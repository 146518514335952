import Model from "../Model";

class DeleteRelations extends Model{

    buildUrl ({ param }) {
        return ['userrelation/deleteall', param];
    }

    all(config = {}) {
        return this.request({ method: 'POST', action: 'all', ...config });
    }

    delete(form) {
        return this.request({
            action: 'destroy',
            method: 'POST',
            data: form
        });
    }
}


export default new DeleteRelations;