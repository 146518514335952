export default (authGuard, guestGuard) => [
    
    // {
    //     path: '/',
    //     name: 'home',
    //     redirect: {name: 'login'},
    //     component: () => import(/* webpackChunkName: "login" */ '@/pages/login')
    // },
    
    ...guestGuard([
        {
            path: '/',
            name: 'home',
            redirect: {name: 'login'}
        },
        {
            path: '/login',
            name: 'login',
            meta: { layout: 'fullscreen' },
            component: () => import('@/pages/login')
        },
        {
            path: '/recoverypass',
            name: 'recoverypass',
            meta: { layout: 'fullscreen' },
            component: () => import('@/pages/recoverypass')
        },
        {
            path: '/passreset',
            name: 'passreset',
            meta: { layout: 'fullscreen' },
            component: () => import('@/pages/passreset')
        }
    ]),

    ...authGuard([
        {
            path: '/group',
            name: 'group',
            meta: { layout: 'app' },
            component: () => import('@/pages/group')
        },
        {
            path: '/groupfilters',
            name: 'groupfilters',
            meta: { layout: 'app' },
            component: () => import('@/pages/groupfilters')
        },
        {
            path: '/grouptops',
            name: 'grouptops',
            meta: { layout: 'app' },
            component: () => import('@/pages/grouptops')
        },
        {
            path: '/company/:company',
            name: 'company',
            meta: { layout: 'app' },
            component: () => import('@/pages/company'),
            props: true
        },
        {
            path: '/companytops',
            name: 'companytops',
            meta: { layout: 'app' },
            component: () => import('@/pages/companytops')
        },
        {
            path: '/comercials/:saletype',
            name: 'comercials',
            meta: { layout: 'app' },
            component: () => import('@/pages/comercials')
        },
        // {
        //     path: '/zones/:saletype',
        //     name: 'zones',
        //     meta: { layout: 'app' },
        //     component: () => import('@/pages/zones')
        // },
        {
            path: '/zones',
            name: 'zones',
            meta: { layout: 'app' },
            component: () => import('@/pages/zones')
        },
        {
            path: '/comercial/:comercial',
            name: 'comercial',
            meta: { layout: 'app' },
            component: () => import('@/pages/comercial')
        },
        {
            path: '/representante/:representant',
            name: 'representante',
            meta: { layout: 'app' },
            component: () => import('@/pages/representante')
        },
        {
            path: '/kpis',
            name: 'kpis',
            meta: { layout: 'app' },
            component: () => import('@/pages/kpis')
        },
        {
            path: '/kpiscomercialfilters',
            name: 'kpiscomercialfilters',
            meta: { layout: 'app' },
            component: () => import('@/pages/kpiscomercialfilters')
        },
        {
            path: '/visits',
            name: 'visits',
            meta: { layout: 'app' },
            component: () => import('@/pages/visits')
        },
        {
            path: '/clients',
            name: 'clients',
            meta: { layout: 'app' },
            component: () => import('@/pages/clients')
        },
        {
            path: '/client',
            name: 'client',
            meta: { layout: 'app' },
            component: () => import('@/pages/client')
        },
        {
            path: '/tops',
            name: 'tops',
            meta: { layout: 'app' },
            component: () => import('@/pages/tops')
        },
        {
            path: '/filters',
            name: 'filters',
            meta: { layout: 'app' },
            component: () => import('@/pages/filters')
        },
        {
            path: '/comments',
            name: 'comments',
            meta: { layout: 'app' },
            component: () => import('@/pages/comments')
        },    
        {
            path: '/orders',
            name: 'orders',
            meta: { layout: 'app' },
            component: () => import('@/pages/orders')
        },
        {
            path: '/deliverynote',
            name: 'deliverynote',
            meta: { layout: 'app' },
            component: () => import('@/pages/deliverynote')
        },
        {
            path: '/rankings',
            name: 'rankings',
            meta: { layout: 'app' },
            component: () => import('@/pages/rankings')
        },
        {
            path: '/representants',
            name: 'representants',
            meta: { layout: 'app' },
            component: () => import('@/pages/representants')
        },
        {
            path: '/register',
            name: 'register',
            meta: { layout: 'app' },
            component: () => import('@/pages/register')
        },
        {
            path: '/profile',
            name: 'profile',
            meta: { layout: 'app' },
            component: () => import('@/pages/profile')
        },
        {
            path: '/changepass',
            name: 'changepass',
            meta: { layout: 'fullscreen' },
            component: () => import('@/pages/changepass')
        },
        {
            path: '/accept',
            name: 'accept',
            meta: { layout: 'fullscreen' },
            component: () => import('@/pages/accept')
        },
        {
            path: '/comercialfilters',
            name: 'comercialfilters',
            meta: { layout: 'app' },
            component: () => import('@/pages/comercialfilters')
        },
        {
            path: '/kpisrepresentante',
            name: 'kpisrepresentante',
            meta: { layout: 'app' },
            component: () => import('@/pages/kpisrepresentante')
        },
        {
            path: '/companyfilters',
            name: 'companyfilters',
            meta: { layout: 'app' },
            component: () => import('@/pages/companyfilters')
        },
        {
            path: '/representantfilters',
            name: 'representantfilters',
            meta: { layout: 'app' },
            component: () => import('@/pages/representantfilters')
        },
        {
            path: '/representantclients',
            name: 'Representantclients',
            meta: { layout: 'app' },
            component: () => import('@/pages/Representantclients')
        },
        {
            path: '/filterperiod',
            name: 'filterperiod',
            meta: { layout: 'app' },
            component: () => import('@/pages/filterperiod')
        },
        {
            path: '/otherfilters',
            name: 'otherfilters',
            meta: { layout: 'app' },
            component: () => import('@/pages/otherfilters')
        },
        {
            path: '/representantvisits',
            name: 'representantvisits',
            meta: { layout: 'app' },
            component: () => import('@/pages/representantvisits')
        },
        {
            path: '/representantclient',
            name: 'representantclient',
            meta: { layout: 'app' },
            component: () => import('@/pages/representantclient')
        },
        {
            path: '/filterstop',
            name: 'filterstop',
            meta: { layout: 'app' },
            component: () => import('@/pages/filterstop')
        },
        {
            path: '/comercialtops',
            name: 'comercialtops',
            meta: { layout: 'app' },
            component: () => import('@/pages/comercialtops')
        },
        {
            path: '/representanttops',
            name: 'representanttops',
            meta: { layout: 'app' },
            component: () => import('@/pages/representanttops')
        },
        {
            path: '/comercialclienttops',
            name: 'comercialclienttops',
            meta: { layout: 'app' },
            component: () => import('@/pages/comercialclienttops')
        },
        {
            path: '/representantclienttops',
            name: 'representantclienttops',
            meta: { layout: 'app' },
            component: () => import('@/pages/representantclienttops')
        },
        {
            path: '/videowall',
            name: 'videowall',
            meta: { layout: 'fullscreen' },
            component: () => import('@/pages/videowall')
        },
        // {
        //     path: '/zone/:idzone',
        //     name: 'zone',
        //     meta: { layout: 'app' },
        //     component: () => import('@/pages/zone')
        // },
        {
            path: '/zone',
            name: 'zone',
            meta: { layout: 'app' },
            component: () => import('@/pages/zone')
        },
        {
            path: '/newuser',
            name: 'newuser',
            meta: { layout: 'app' },
            component: () => import('@/pages/newuser')
        },
        {
            path: '/users',
            name: 'users',
            meta: { layout: 'app' },
            component: () => import('@/pages/users')
        },
        {
            path: '/edituser',
            name: 'edituser',
            meta: { layout: 'app' },
            component: () => import('@/pages/edituser')
        },
        {
            path: '/filterperiodvisits',
            name: 'filterperiodvisits',
            meta: { layout: 'app' },
            component: () => import('@/pages/filterperiodvisits')
        },
        {
            path: '/otherfiltersrepresentant',
            name: 'otherfiltersrepresentant',
            meta: { layout: 'app' },
            component: () => import('@/pages/otherfiltersrepresentant')
        },
        {
            path: '/indicatorfilterszones',
            name: 'indicatorfilterszones',
            meta: { layout: 'app' },
            component: () => import('@/pages/indicatorfilterszones')
        },
        {
            path: '/relations',
            name: 'relations',
            meta: { layout: 'app' },
            component: () => import('@/pages/relations')
        },
        {
            path: '/clientfilters',
            name: 'clientfilters',
            meta: { layout: 'app' },
            component: () => import('@/pages/clientfilters')
        },
        {
            path: '/syncs',
            name: 'syncs',
            meta: { layout: 'app' },
            component: () => import('@/pages/syncs')
        },
        {
            path: '/workdays',
            name: 'workdays',
            meta: { layout: 'app' },
            component: () => import('@/pages/workdays')
        },
        {
            path: '/analitycs',
            name: 'analitycs',
            meta: { layout: 'app' },
            component: () => import('@/pages/analitycs')
        },
        {
            path: '/analitycsUser',
            name: 'analitycsUser',
            meta: { layout: 'app' },
            component: () => import('@/pages/analitycsUser')
        },
        {
            path: '/filtersAnalitycs',
            name: 'filtersAnalitycs',
            meta: { layout: 'app' },
            component: () => import('@/pages/filtersAnalitycs')
        },
        {
            path: '/translations',
            name: 'translations',
            meta: { layout: 'app' },
            component: () => import('@/pages/translations')
        }
        
        
    ])

];