import Model from "../Model";

class downloadpdf extends Model{

    buildUrl() {
        return ['useradmin/getExcel'];
    }

    all(config = {}) {
        return this.request({ method: 'GET', action: 'all', ...config });
    }

    create(form) {
        return this.request({
            action: 'store',
            method: 'POST',
            data: form
        });
    }
}


export default new downloadpdf;