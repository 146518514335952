import VueI18n from 'vue-i18n'
import Vue from 'vue'

import es from './es/messages'
import en from './en/messages'
import fr from './fr/messages'
import it from './it/messages'
import de from './de/messages'


Vue.use(VueI18n)

const messages = {
    es,
    en,
    fr,
    it,
    de
}
const languages = ['es', 'en', 'fr', 'it', 'de'];
const lang = navigator.language;
let locale = 'es';

if (languages.includes(lang.split("-")[0])) {
    locale = lang.split("-")[0];
}

if (localStorage.getItem('locale')) {

    locale = localStorage.getItem('locale')

} else {

    if (locale === 'en'){
        localStorage.setItem('locale', 'en-EN');
    } else if (locale === 'fr'){
        localStorage.setItem('locale', 'fr-FR');
    } else if (locale === 'it'){
        localStorage.setItem('locale', 'it-IT')
    } else if (locale === 'de'){
        localStorage.setItem('locale', 'de-DE')
    } else{
        localStorage.setItem('locale', 'es-ES')
    }

}

console.log(locale)
const i18n = new VueI18n({
    locale: locale,
    messages,
    fallbackLocale: 'es'
})

export default i18n