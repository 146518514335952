import Model from "../Model";

class RegisterUser extends Model{

    buildUrl () {
        return ['/useradmin/register'];
    }

    all(config = {}) {
        return this.request({ method: 'POST', action: 'all', ...config });
    }

    create(form) {
        return this.request({
            action: 'store',
            method: 'POST',
            data: form
        });
    }

    update(attributes, config = {}) {
        return this.request({ method: 'POST', action: 'update', param: this.primaryKey(attributes), data: attributes, ...config });
    }
}


export default new RegisterUser;