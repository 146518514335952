import Model from "../Model";

class AnalitycsAllUser extends Model{

    buildUrl ({ param }) {
        return ['/analytics/get', param];
    }

    all(config = {}) {
        return this.request({ method: 'POST', action: 'all', ...config });
    }
}


export default new AnalitycsAllUser;