<template>

    <div  class="h-auto px-2 mt-2 flex flex-col justify-center items-start">
        <div class="h-8 w-full">
            <div class="h-full w-full rounded-lg flex flex-row justify-between items-center px-2" :class="'bg-box-'+mode">
                <select name="month" v-model="month" @change='event => updateMonth(event.target.value)' class="h-full w-full" :class="'bg-box-'+mode+' text-dfont-'+mode">
                    <option :value="el.value" v-for="(el) in months" :key="el.value" >{{el.name}}</option>
                </select>
            </div>
        </div>
     </div>
           
</template>
<script>
import { state, actions } from '@/store';

export default {
   props:{
        mode:{
            
        }
   },
    data() {
        return {
        
              months: [
                { 
                    name: this.$t("january"),
                    value: 1
                },
                { 
                    name: this.$t("february"),
                    value: 2
                },
                { 
                    name: this.$t("march"),
                    value: 3
                },
                { 
                    name: this.$t("april"),
                    value: 4
                },
                { 
                    name: this.$t("may"),
                    value: 5
                },
                { 
                    name: this.$t("june"),
                    value: 6
                },
                 { 
                    name: this.$t("july"),
                    value: 7
                },
                { 
                    name: this.$t("august"),
                    value: 8
                },
                { 
                    name: this.$t("september"),
                    value: 9
                },
                { 
                    name: this.$t("october"),
                    value: 10
                },
                { 
                    name: this.$t("november"),
                    value: 11
                },
                { 
                    name: this.$t("december"),
                    value: 12
                }
            ],
          
            month: state.month,
            
         
        }
    },
    methods: {
        updateMonth(month){
            actions.setMonth(month)
        },
        
        
    },
    
  
    
}

</script>