import Model from "../Model";

class EditRelation extends Model{

    buildUrl ({ param }) {
        return ['userrelation/change', param];
    }

    all(config = {}) {
        return this.request({ method: 'POST', action: 'all', ...config });
    }

    update(attributes, config = {}) {
        return this.request({ method: 'POST', action: 'update', param: this.primaryKey(attributes), data: attributes, ...config });
    }
}


export default new EditRelation;