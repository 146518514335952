const messages = {
    forgottenPassword: 'Have you forgotten the password?',
    group: 'Group',
    period: 'Period',
    customer: 'Customer',
    monthly: 'Monthly',
    brands: 'Brands',
    real: 'Real',
    monthlyCapitalLetter: 'Monthly',
    month: 'Month',
    nextDayOC: 'Next OC day',
    exportCapitalLetter: 'EXPORT',
    videowallPermissions: 'No tienes permisos para acceder al videowall',
    quarterly: 'Quarterly',
    quarter: 'Quarter',
    annual: 'Annual',
    operationalAndPending: 'Operational and Pending',
    averagePriceSimplified: 'AP',
    companies: 'Companies',
    indicator: 'Indicator',
    hi: 'Hello',
    profile: 'Profile',
    users: 'Users',
    logOut: 'Log out',
    visits: 'Visits',
    customers: 'Customers',
    continents: 'Continents',
    billing: 'Billing',
    day: 'Day',
    accumulated: 'Accumulated',
    deliveryNotes: 'Delivery notes',
    deliveryNotesDay: 'Delivery notes day',
    scope: 'Scope',
    variation: 'Variation',
    averagePrice: 'Average Price',
    averagePriceLowerCase: 'Average price',
    value: 'Value',
    last12Months: 'Last 12 Months',
    national: 'NATIONAL',
    export: 'EXPORT',
    margin: 'Margin',
    total: 'TOTAL',
    orders: 'Orders',
    portfolio: 'Portfolio',
    current: 'Current',
    lastSynchronization: 'Last synchro',
    topGroups: 'Tops Group',
    appliedFilters: 'Filters applied',
    dimension: 'Dimension',
    place: 'Place',
    growth: 'Growth',
    weight: 'Weight',
    unpaidYear: 'Unpaid Year',
    commercial: 'Commercials',
    company: 'Company',
    zone: 'Zone',
    delete: 'delete',
    relationDeleted: 'Relation deleted',
    edit: 'Edit',
    unpaid: 'Outstanding',
    squareMeters: 'Square Meters',
    article: 'Article',
    active: 'Active',
    appellants: 'Appellants',
    new: 'New',
    loadingOrders: 'Loading Orders',
    totals: 'Totals',
    next: 'Next',
    today: 'Today',
    continent: 'Continent',
    country: 'Country',
    province: 'Province',
    howToGetThere: 'How to get there',
    writeMessage: 'Write a message',
    legalRequirements: 'Legal Requirements',
    legalRequirementsTextOne: 'The use of Pamesa Group App as well as any of its services implies the reading, understanding and acceptance of this legal notice by the user.',
    legalRequirementsTextTwo: 'In compliance with the Act 34/2002, from July 11th, by Services of the Association for the Electronic Information and Commerce, we inform the following aspects.',
    legalRequirementsTextThree: '- Pamesa Ceramica S.L. located in Almazora, Camino Alcora 8, postal code 12550. If more information is needed don\'t hesitate to contact us by phone at (+34) 964 507 500 or by email to nacional@pamesa.com or export@pamesa.com',
    legalRequirementsTextFour: '- Pamesa Ceramica S.L. a Spanish Company registered in Companies Registry of Castellon de la Plana, Volume 469, Sheet 36, Page 88, CS 935. Registration 1ª dated on February-25-1991 - Tax ID number B-12071486 - VTA No. ESB-12071486.',
    legalRequirementsTextFive: ' - The User voluntarily and expressly accepts that the use of this App is in any case under their sole and exclusive responsibility.',
    legalRequirementsTextSix: '- The credentials assigned to each user are personal and non-transferable, the user being solely responsible for the consequences that may arise from the misuse, disclosure or loss thereof, as well as its use by another person.',
    legalRequirementsTextSeven: '- By using Pamesa Group App the user agrees not to carry out any conduct that could damage the image, interests and rights of Grupo Pamesa or third parties or that could damage, disable or overload the Company, or that in any way prevent its normal use.',
    legalRequirementsTextEight: ' - Pamesa Group Content are made available to the user by Pamesa Ceramics S.L. with information from its own sources.',
    legalRequirementsTextNine: '- Using our servicies does not give you ownership of any intelectual property rights in Pamesa Group. The reproduction, transformation, distribution, public communication, extraction, reuse, forwarding or use of any nature, by any means or procedure, of any of them is expressly forbiden for the User; except in cases where it is legally permitted or authorized by the owner of the corresponding rights.',
    legalRequirementsTextTen: 'These General Conditions are regulated by the Spanish Law',
    orderBook: 'Order Book',
    readAndAccept: 'I have read and accept',
    sales: 'Sales',
    annualSalesHistory: 'Annual sales history',
    firstAccessChangePassword: 'The password need to be changed the first time you log in',
    newPassword: 'New Password',
    password: 'Password',
    repeatPassword: 'Repeat Password',
    changePassword: 'Change Password',
    passwordSuccessfullyChanged: 'Password successfully changed.',
    unsecuredPassword: 'Unsecured Password',
    passwordMustBe: 'The password must be:',
    betweenCharacters: 'Between 8 and 16 characters',
    leastOneDigit: 'At least one digit',
    leastLowerAndUpperCase: 'At least one Small letter and one Capital Letter',
    promotionalExpenses: 'Promotional expenses',
    aboutTheClient: 'About the client',
    colors: 'Colors',
    models: 'Models',
    formats: 'Formats',
    quality: 'Quality',
    spanishProvinces: 'Provinces of Spain',
    averagePriceOfYourPurchases: 'Average price of your purchases',
    closeLastPurchase: 'Close Last purchase',
    superFamily: 'Super Family',
    mostPurchasedFormats: 'Most purchased formats',
    mostPurchasedFamily: 'Most Purchased Family',
    meters: 'Meters',
    activeClients: 'Active Clients',
    messageSentSuccessfully: 'Message sent successfully',
    theMessageCouldNotBeSent: 'The message could not be sent',
    noNewMessage: 'No new messages',
    allYouraAreas: 'All Your Areas',
    visitsZone: 'Visits Zone',
    representatives: 'Representatives',
    noCustomers: 'No Customers',
    relations: 'Relationships',
    addRelations: 'Add Relationships',
    editRelations: 'Edit Relationships',
    viewRelations: 'View Relationships',
    allZones: 'All Zones',
    selectALocation: 'Select a Location',
    selectAnAgent: 'Select an Agent',
    select: 'Select',
    addRelation: 'Add Relation',
    userList: 'User List',
    apply: 'Apply',
    name: 'Name',
    nameZone: 'Zone Name',
    yourPosition: 'Your Position',
    theBest: 'The Best',
    username: 'Username',
    recoveryText: 'An email has been sent to you with a link to reset your password. Please double check your email.',
    familyName: 'Family Name',
    families: 'Familias',
    updatePassword: 'Update Password',
    comments: 'Comments',
    accumulatedBilling: 'Accumulated Billing',
    filters: 'Filters',
    noData: 'No data',
    firstSurname: 'First Surname',
    secondSurname: 'Second Surname',
    unsafePassword: 'Unsafe Password',
    cancel: 'Cancel',
    change: 'Change',
    administrator: 'Administrator',
    createUser: 'Create User',
    promotion: 'Promotion',
    totalOperational: 'Total operational',
    nearbyOperational: 'Nearby operational',
    totalsOC: 'Total Loading Orders',
    sellingRate: 'Selling Rate',
    selectCompany: 'Select a Company',
    confirmPassword: 'Confirm Password',
    selectRol: 'Select Rol',
    lastSevenDays: 'Last 7 Days',
    changeLanguage: 'Change Language',
    year: 'Year',
    close: 'Close',
    countries: 'Countries',
    europe: 'Europe',
    southOfAmerica: 'South America',
    centralOfAmerica: 'Central America',
    northOfAmerica: 'North America',
    asia: 'Asia',
    oceania: 'Oceania',
    africa: 'Africa',
    all: 'All',
    quarterlyVariation: 'Quarterly variation',
    monthlyVariation: 'Monthly variation',
    annualVariation: 'Annual variation',
    orderBookCurrent: 'Current order book',
    accumulatedSquareMeters: 'Accumulated square meters',
    allCountries: 'All countries',
    allContinents: 'All Continents',
    sync:'Synchronization',
    syncs:'Synchronizations',
    lastSync: 'Last Synchronization',
    labdays: 'Work days',
    useApp: 'Using the app',
    currentValue: 'Current month value',
    selectMonth: 'Select month',
    january:'January',
    february:'February',
    march:'March',
    april:'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
    general: 'General',
    mensajes: 'Messages',
    buscarZona: 'Search Zone...',
    buscarComercial: 'Search Commercial...',
    volver: 'Return',
    darkmode: 'Dark Mode',
    buscarusuario: 'Search User...',
    buscarcliente: 'Search Client...',
    home: 'Home',
    buscarrepresentant: 'Search Representative...',
    representant: 'Representative',
    nolocationtext: 'Clients do not have location data',
    sincs: 'Synchronizations',
    oksyncro: 'Last successful sync',
    errorsyncro: 'Last sync failed',
    doingsyncro: 'Running sync',
    completesyncro: 'Run Sync. Complete',
    nosalessyncro: 'Run Sync. No Sales',
    salesdays: 'Days with sales',
    usersaggregate: 'Adding users',
    buscarpor: 'Search by',
    rol: 'Role',
    todos: 'ALL',
    todas: 'ALL',
    allgrouphours: 'All Group Hours',
    hoursbycompany: 'Hours per Company',
    zonehours: 'Hours of Zone Managers',
    representanthours: 'Representative Hours',
    devicehours: 'Hours per Device',
    allhours: 'All hours',
    allhoursof: 'All hours of ',
    pchours: 'Hours on PC',
    mobilehours: 'Hours on mobile',
    tablethours: 'Hours on tablet',
    userresume: 'User Summary',
    agent: 'Agent',
    zones: 'Zones',
    edituser: 'Edit User',
    newuser: 'Create User',
    recovery: 'Recover Password'
}

export default messages
