import Model from "../Model";

class EditUser extends Model{

    buildUrl () {
        return ['/useradmin/change'];
    }

    all(config = {}) {
        return this.request({ method: 'POST', action: 'all', ...config });
    }

    // edit(form) {
    //     return this.request({
    //         action: 'update',
    //         method: 'POST',
    //         data: form
    //     });
    // }

    update(attributes, config = {}) {
        return this.request({ method: 'POST', action: 'update', param: this.primaryKey(attributes), data: attributes, ...config });
    }
}


export default new EditUser;