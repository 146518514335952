import { state,actions } from '@/store';
import element from 'element-ui';

export const routeByRole = (role) => {

    actions.setIndustrialGroup(null)
    actions.setTopDimension('Company')
    actions.setTopDimensionName('Marcas')

    actions.setTopIndicator(0)
    actions.setTopIndicatorName('Facturación')

    switch(role) {

        case 'Propiedad':

            actions.setTopDimension('Company')
            actions.setTopDimensionName('Marcas')

            actions.setTopIndicator(0)
            actions.setTopIndicatorName('Facturación')

            if(state.user.videoWall == 1){

                return { name: 'videowall' }

            } else {

                return { name : 'group' };

            }
            
            break;

        case 'Gerente':


            if(state.user.relation.length > 0){

                actions.setCompanySelected(state.user.relation[0].company.FrontName);
                actions.setIdCompany(state.user.relation[0].IdCompany.toUpperCase());

                if(state.user.relation[0].company.IndustrialGroup == 0){

                    actions.setIndustrialGroup(null);

                } else {

                    actions.setIndustrialGroup(true);

                }

                if(state.user.videoWall == 1){

                    return { name: 'videowall' }

                } else {

                    return { name : 'company' };

                }

                

            } else {
                
                element.Message.error('El usuario no tiene los datos necesarios');
                actions.resetAuth();
            }

            break;
            
        case 'Comercial':

            var error = false;

            if(state.user.relation.length > 0){

                actions.setComercialSelected(state.user.id)

                actions.setComercialLogged(state.user)

                if(state.user.relation[0].company.FrontName){

                    actions.setCompanySelected(state.user.relation[0].company.FrontName);

                } else {
                    error = true
                }

                if(state.user.relation[0].IdCompany){

                    actions.setIdCompany(state.user.relation[0].IdCompany.toUpperCase());
                    
                } else {
                    error = true
                } 

                if(state.user.relation[0].IdSaleType){

                    if(state.saleTypeSelected == null){
                        actions.setSaleTypeSelected(state.user.relation[0].IdSaleType);
                    }

                } else {
                    error = true
                }

                if(state.user.relation[0].IdZoneLink){

                    actions.setIdZone(state.user.relation[0].zonelink.IdZone);

                } else {
                    error = true
                }

                if(state.user.relation[0].saletype){

                    if(state.zoneSelected == null){

                        actions.setZoneSelected(state.user.relation[0].saletype.Name);

                    }

                } else {
                    error = true
                }

                if(state.user.relation[0].zonelink){

                    // actions.setComercialSelected(state.user.relation[0].zonelink.zone);
                    actions.setZone(state.user.relation[0].zonelink.zone);

                } else{
                    error = true 
                }

                if(state.user.relation[0].saletype){

                    actions.setTypeComercialsSelected(state.user.relation[0].saletype.Name);

                } else{
                    error = true 
                }

                if(state.user.relation[0].zonelink){

                    actions.setFilterZoneComercial(state.user.relation[0].zonelink.IdZone);
                    actions.setFilterZoneComercialName(state.user.relation[0].zonelink.zone.Name);

                } else{
                    error = true 
                }

                if(state.user.relation[0].company.IndustrialGroup == 0){

                    actions.setIndustrialGroup(null);

                } else {

                    actions.setIndustrialGroup(true);

                }

                if(state.user.id){

                    actions.setUserId(state.user.id);

                } else{
                    error = true 
                }

                if(state.user.relation){

                    actions.setRelation(state.user.relation);

                } else{
                    error = true 
                }

                if(!error){

                    return { name : 'zone' }

                } else {

                    element.Message.error('El usuario no tiene los datos necesarios');
                    actions.resetAuth();

                }

                

                if(state.zoneSelected == 'Nacional'){
    
                    actions.setTopDimension('Province');
                    actions.setTopDimensionName('Provincias de España');
    
    
                } else {
    
                    actions.setTopDimension('Country');
                    actions.setTopDimensionName('Paises');
    
                }

            } else {
                
                element.Message.error('El usuario no tiene los datos necesarios');
                actions.resetAuth();
            }

            break;
            
        case 'Representante':

        if(state.user.relation.length > 0){
            
            actions.setCompanySelected(state.user.relation[0].company.FrontName);
            actions.setIdCompany(state.user.relation[0].IdCompany);
            // actions.setSaleTypeSelected(state.user.relation[0].IdSaleType);
            // actions.setIdZone(state.user.relation[0].zonelink.IdZone);
            // actions.setZoneSelected(state.user.relation[0].zonelink.zone.Name);
            actions.setEmployeeSelected(state.user.relation[0].employeelink.employee);
            actions.setIdEmployeeSelected(state.user.relation[0].employeelink.IdEmployee);

            return { name : 'representante' };

        } else {
            
            element.Message.error('El usuario no tiene los datos necesarios');
            actions.resetAuth();

        }

        break;
        
    };
};

export const routeByUser = ({ forcepassword, firstlogin, rol }) => {

    if(state.user.relation.length > 0 || rol.Name == 'Propiedad'){

        if (firstlogin) {
            return { name : 'accept' };
        } else if (forcepassword) {
            return { name : 'changepass' };
        } else {
            return routeByRole(rol.Name);
        }

    } else {

        element.Message.error('El usuario no tiene los datos necesarios');
        actions.resetAuth();

    }
    
    

};