import Model from "../Model";

class Devices extends Model{

    buildUrl ({ param }) {
        return ['analytics/getdevice', param];
    }

    all(config = {}) {
        return this.request({ method: 'POST', action: 'all', ...config });
    }
}


export default new Devices;