import Vue from 'vue';
import { toCookie, toLocal } from './storages';

const store = {
    token: null,
    user: null,
    employees: [],
    period: 'month',
    month: new Date().getMonth()+1,
    dimensionGroup: 0,
    companySelected: null,
    zoneSelected: null,
    saleTypeSelected: null,
    idCompany: null,
    idZone: null,
    comercialSelected: null,
    filterKpiComercialSelected: 'Mensual',
    employeSelected: null,
    idEmployeeSelected: null,
    typeComercialsSelected: null,
    dimensionRepresentantsSelected: 0,
    representantSelected: null,
    messageError: null,
    dimensionCompany: 0,
    filterZoneComercial: null,
    clientSelected: null,
    dimensionName:'Facturación',
    industrialGroup: null,
    userId: 0,
    relation:[],
    userZoneSelected:null,
    filterZoneComercialName: null,
    topDimension: null,
    topPeriod: 'month',
    topIndicator: null,
    topPlace: null,
    topDimensionName: null,
    topIndicatorName: null,
    topPlaceName: null,
    topTypePlace: null,
    actualTopTypePlace: null,
    lastPage: null,
    zone: null,
    comercialLogged:null,
    typeclienttops:null,
    videowallPeriod:'month',
    videowallTypePlace:'Country',
    videowallPlace: null,
    videowallIndicator:0,
    videowallIndicatorName:'Facturación',
    videowallDimension:'Country',
    videowallDimensionName:'Paises',
    videowallTypePlaceName:'Pais',
    videowallPlaceName:null,
    videowallCompany:null,
    videowallIndustrialGroup:null,
    videowallGrowth: false,
    videowallGrowthName: 'Crecimiento',
    employeeName:null,
    refreshTime: 900000,
    userToEdit:null,
    refresh:false,
    defaultLanguage: localStorage.getItem('locale') || 'es-ES',
    userAnalitycs:null,
    periodAnalitycs:'month',
    companyAnalitycs:null,
    mode: 'whitemode',
    isMobile: false
};


toLocal(store);
const state = Vue.observable(store);

const actions = {
    setIsMobile(isMobile){
        state.isMobile = isMobile
    },
    setLanguage(iso) {
        localStorage.setItem('locale', iso)
        state.defaultLanguage = iso
    },
    setToken(token) {
        state.token = token;
    },
    setUser(user) {
        state.user = user;
    },
    resetAuth() {
        state.token = null,
        state.user = null,
        state.employees = [],
        state.dimensionGroup = 0,
        state.companySelected = null,
        state.zoneSelected = null,
        state.saleTypeSelected = null,
        state.idCompany = null,
        state.idZone = null,
        state.comercialSelected = null,
        state.filterKpiComercialSelected = 'Mensual',
        state.employeSelected = null,
        state.idEmployeeSelected = null,
        state.typeComercialsSelected = null,
        state.dimensionRepresentantsSelected = 0,
        state.representantSelected = null,
        state.messageError = null,
        state.dimensionCompany = 0,
        state.period = 'month',
        state.month = new Date().getMonth() +1,
        state.filterZoneComercial = null,
        state.clientSelected = null,
        state.dimensionName ='Facturación',
        state.industrialGroup = null,
        state.userId = 0,
        state.relation =[],
        state.userZoneSelected =null,
        state.filterZoneComercialName = null,
        state.topDimension = null,
        state.topPeriod = 'month',
        state.topIndicator = null,
        state.topPlace = null,
        state.topDimensionName = null,
        state.topIndicatorName = null,
        state.topPlaceName = null,
        state.topTypePlace = null,
        state.actualTopTypePlace = null,
        state.lastPage = null,
        state.zone = null,
        state.comercialLogged =null,
        state.typeclienttops =null,
        state.videowallPeriod ='month',
        state.videowallTypePlace ='Country',
        state.videowallPlace = null,
        state.videowallIndicator =0,
        state.videowallIndicatorName ='Facturación',
        state.videowallDimension ='Country',
        state.videowallDimensionName ='Paises',
        state.videowallTypePlaceName ='Pais',
        state.videowallPlaceName =null,
        state.videowallCompany =null,
        state.videowallIndustrialGroup =null,
        state.videowallGrowth = false,
        state.videowallGrowthName = 'Crecimiento',
        state.employeeName =null,
        state.refreshTime = 900000,
        state.userToEdit =null,
        state.refresh =false,
        state.defaultLanguage = localStorage.getItem('locale') || 'es-ES',
        state.userAnalitycs =null,
        state.periodAnalitycs ='month',
        state.companyAnalitycs =null,
        state.mode = 'whitemode'
    },
    setEmployees(employees) {
        state.employees = employees;
    },
    setDimensionGroup(dimensionGroup) {
        state.dimensionGroup = dimensionGroup;
    },
    setCompanySelected(companySelected) {
        state.companySelected = companySelected;
    },
    setZoneSelected(zoneSelected) {
        state.zoneSelected = zoneSelected;
    },
    setSaleTypeSelected(saleTypeSelected) {
        state.saleTypeSelected = saleTypeSelected;
    },
    setIdCompany(idCompany) {
        state.idCompany = idCompany;
    },
    setIdZone(idZone) {
        state.idZone = idZone;
    },
    setComercialSelected(comercialSelected) {
        state.comercialSelected = comercialSelected;
    },
    setFilterKpiComercialSelected(filterKpiComercialSelected) {
        state.filterKpiComercialSelected = filterKpiComercialSelected;
    },
    setEmployeeSelected(employeSelected) {
        state.employeSelected = employeSelected;
    },
    setIdEmployeeSelected(idEmployeeSelected) {
        state.idEmployeeSelected = idEmployeeSelected;
    },
    setTypeComercialsSelected(typeComercialsSelected){
        state.typeComercialsSelected = typeComercialsSelected
    },
    setRepresentantSelected(representantSelected){
        state.representantSelected = representantSelected
    },
    setMessageError(messageError){
        state.messageError = messageError
    },
    setDimensionCompany(dimensionCompany){
        state.dimensionCompany = dimensionCompany
    },
    setPeriod(period){
        state.period = period
    },
    setMonth(month){
        state.month = month
    },
    setFilterZoneComercial(filterZoneComercial){
        state.filterZoneComercial = filterZoneComercial
    },
    setClientSelected(clientSelected){
        state.clientSelected = clientSelected
    },
    setDimensionName(dimensionName){
        state.dimensionName = dimensionName
    },
    setIndustrialGroup(industrialGroup){
        state.industrialGroup = industrialGroup
    },
    setUserId(userId){
        state.userId = userId
    },
    setRelation(relation){
        state.relation = relation
    },
    setUserZoneSelected(userZoneSelected){
        state.userZoneSelected = userZoneSelected
    },
    setFilterZoneComercialName(filterZoneComercialName){
        state.filterZoneComercialName = filterZoneComercialName
    },
    setTopDimension(topDimension){
        state.topDimension = topDimension
    },
    setTopPeriod(topPeriod){
        state.topPeriod = topPeriod
    },
    
    setTopIndicator(topIndicator){
        state.topIndicator = topIndicator
    },
    setTopPlace(topPlace){
        state.topPlace = topPlace
    },
    setTopDimensionName(topDimensionName){
        state.topDimensionName = topDimensionName
    },
    setTopIndicatorName(topIndicatorName){
        state.topIndicatorName = topIndicatorName
    },
    setTopPlaceName(topPlaceName){
        state.topPlaceName = topPlaceName
    },
    setTopTypePlace(topTypePlace){
        state.topTypePlace = topTypePlace
    },
    setActualTopTypePlace(actualTopTypePlace){
        state.actualTopTypePlace = actualTopTypePlace
    },
    setLastPage(lastPage){
        state.lastPage = lastPage
    },
    setZone(zone){
        state.zone = zone
    },
    setVideowallPeriod(videowallPeriod){
        state.videowallPeriod = videowallPeriod
    },
    setVideowallTypePlace(videowallTypePlace){
        state.videowallTypePlace = videowallTypePlace
    },
    setVideowallPlace(videowallPlace){
        state.videowallPlace = videowallPlace
    },
    setVideowallIndicator(videowallIndicator){
        state.videowallIndicator = videowallIndicator
    },
    setVideowallDimension(videowallDimension){
        state.videowallDimension = videowallDimension
    },
    setComercialLogged(comercialLogged){
        state.comercialLogged = comercialLogged
    },
    setVideowallTypePlaceName(videowallTypePlaceName){
        state.videowallTypePlaceName = videowallTypePlaceName
    },
    setVideowallPlaceName(videowallPlaceName){
        state.videowallPlaceName = videowallPlaceName
    },
    setVideowallIndicatorName(videowallIndicatorName){
        state.videowallIndicatorName = videowallIndicatorName
    },
    setTypeclienttops(typeclienttops){
        state.typeclienttops = typeclienttops
    },
    setVideowallDimensionName(videowallDimensionName){
        state.videowallDimensionName = videowallDimensionName
    },
    setVideowallCompany(videowallCompany){
        state.videowallCompany = videowallCompany
    },
    setUserToEdit(userToEdit){
        state.userToEdit = userToEdit
    },
    setVideowallIndustrialGroup(videowallIndustrialGroup){
        state.videowallIndustrialGroup = videowallIndustrialGroup
    },
    setVideowallGrowth(videowallGrowth){
        state.videowallGrowth = videowallGrowth
    },
    setVideowallGrowthName(videowallGrowthName){
        state.videowallGrowthName = videowallGrowthName
    },
    setEmployeeName(employeeName){
        state.employeeName = employeeName
    },
    setRefresh(refresh){
        state.refresh = refresh
    },
    setUserAnalitycs(userAnalitycs){
        state.userAnalitycs = userAnalitycs
    },
    setPeriodAnalitycs(periodAnalitycs){
        state.periodAnalitycs = periodAnalitycs
    },
    setCompanyAnalitycs(companyAnalitycs){
        state.companyAnalitycs = companyAnalitycs
    },
    setMode(mode){
        state.mode = mode
    }
};

export {
    state,
    actions
};
