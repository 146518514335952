import Model from "../Model";

class Comercialtorepresentantmessage extends Model{

    buildUrl() {
        return ['internalmessage/sendallemployee'];
    }

    all(config = {}) {
        return this.request({ method: 'POST', action: 'all', ...config });
    }

    create(form) {
        return this.request({
            action: 'store',
            method: 'POST',
            data: form
        });
    }
}


export default new Comercialtorepresentantmessage;