import Model from "../Model";

class Changeme extends Model{

    buildUrl ({ param }) {
        return ['changueme'];
    }

    beforeRequest(request) {
        request.data.user = JSON.stringify(request.data);
        return super.beforeRequest(request);
    }

    update(attributes, config = {}) {
        return this.request({ method: 'POST', action: 'update', param: this.primaryKey(attributes), data: attributes, ...config });
    }

}


export default new Changeme;