import Model from "../Model";

class Groupinfo extends Model{

    buildUrl ({ param }) {
        return ['groupview/info', param];
    }

    all(config = {}) {
        return this.request({ method: 'POST', action: 'all', ...config });
    }
}


export default new Groupinfo;