const messages = {
    forgottenPassword: '¿Ha olvidado su contraseña?',
    customer: 'Cliente',
    group: 'Grupo',
    period: 'Periodo',
    monthly: 'Mensual',
    monthlyCapitalLetter: 'Mensual',
    month: 'Mes',
    models: 'Modelos',
    formats: 'Formatos',
    europe: 'Europa',
    southOfAmerica: 'América del Sur',
    centralOfAmerica: 'América Central',
    northOfAmerica: 'América del Norte',
    asia: 'Asia',
    oceania: 'Oceania',
    africa: 'Africa',
    quarterly: 'Trimestral',
    quality: 'Calidad',
    allContinents: 'Todos los Continentes',
    colors: 'Colores',
    quarter: 'Trimestre',
    spanishProvinces: 'Provincias de España',
    annual: 'Anual',
    companies: 'Empresas',
    superFamily: 'Súper Familia',
    operationalAndPending: 'Operativas y Pendientes',
    averagePriceSimplified: 'PM',
    indicator: 'Indicador',
    hi: 'Hola',
    profile: 'Perfil',
    users: 'Usuarios',
    logOut: 'Cerrar sesión',
    visits: 'Visitas',
    continents: 'Continentes',
    customers: 'Clientes',
    billing: 'Facturación',
    day: 'Día',
    accumulated: 'Acumulado',
    deliveryNotes: 'Albaranes',
    deliveryNotesDay: 'Albaranes día',
    scope: 'Alcance',
    variation: 'Variación',
    averagePriceLowerCase: 'Precio medio',
    value: 'Valor',
    last12Months: 'Últimos 12 meses',
    national: 'Nacional',
    export: 'Exportación',
    exportCapitalLetter: 'EXPORTACIÓN',
    margin: 'Margen',
    total: 'Total',
    nextDayOC: 'OC proximo día',
    brands: 'Marcas',
    orders: 'Pedidos',
    portfolio: 'Cartera',
    current: 'Actual',
    lastSynchronization: 'Última sincronización',
    topGroups: 'Tops Grupo',
    appliedFilters: 'Filtros aplicados',
    dimension: 'Dimensión',
    place: 'Lugar',
    growth: 'Crecimiento',
    weight: 'Peso',
    unpaidYear: 'Impagos año',
    commercial: 'Comerciales',
    company: 'Compañía',
    zone: 'Zona',
    delete: 'Borrar',
    relationDeleted: 'Relación borrada',
    edit: 'Editar',
    unpaid: 'Impagos',
    squareMeters: 'Metros cuadrados',
    article: 'Artículo',
    active: 'Activo',
    appellants: 'Recurrentes',
    new: 'Nuevos',
    loadingOrders: 'Ordenes de carga',
    totals: 'Totales',
    next: 'Próximo',
    today: 'hoy',
    continent: 'Continente',
    country: 'País',
    province: 'Provincia',
    howToGetThere: 'Como llegar',
    writeMessage: 'Escriba un mensaje...',
    legalRequirements: 'Condiciones legales',
    legalRequirementsTextOne: 'La utilización de la aplicación del Grupo Pamesa así como de cualquiera de sus servicios implica la lectura, comprensión y aceptación del presente aviso legal por parte del usuario.',
    legalRequirementsTextTwo: 'En cumplimiento de lo establecido en la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y el Comercio Electrónico, se informa de los siguientes aspectos:',
    legalRequirementsTextThree: '- Pamesa Cerámica S.L. tiene el domicilio social en Almazora, Camino Alcora número 8 y Código Postal 12550. Para mayor información puede contactar con nosotros a través del número de teléfono 964 507 500, o bien a través de la siguiente dirección de correo electrónico nacional@pamesa.com o export@pamesa.com\n',
    legalRequirementsTextFour: '- Pamesa Cerámica S.L. está inscrita en el Registro Mercantil de Castellón de la Plana, Tomo 469, Libro 36, Folio 88, Hoja CS 935, Inscripción 1ª, Fecha 25-Febrero 1991 – N.I.F. B-12071486 – V.T.A – ESB-12071486.\n',
    legalRequirementsTextFive: ' - El Usuario acepta voluntaria y expresamente que el uso de esta aplicación se realiza en todo caso bajo su única y exclusiva responsabilidad.\n',
    legalRequirementsTextSix: '- Las credenciales asignadas a cada usuario son personales e intransferibles, siendo el usuario el único responsable de las consecuencias que puedan derivarse del mal uso, divulgación o pérdida de las mismas, así como su uso por otra persona.\n',
    legalRequirementsTextSeven: '- En la utilización de la aplicación del Grupo Pamesa, el Usuario se compromete a no llevar a cabo ninguna conducta que pudiera dañar la imagen, los intereses y los derechos de Grupo Pamesa o de terceros o que pudiera dañarlo, inutilizarlo o sobrecargarlo, o que impidiera, de cualquier forma, la normal utilización del mismo.\n',
    legalRequirementsTextEight: ' - Los Contenidos de Grupo Pamesa son puestos a disposición del Usuario por Pamesa Cerámica S.L. con información procedente de fuentes propias.\n',
    legalRequirementsTextNine: '- Mediante estas Condiciones Generales no se cede ningún derecho de propiedad intelectual o industrial sobre la aplicación de Grupo Pamesa ni sobre ninguno de sus elementos integrantes, quedando expresamente prohibidos al Usuario la reproducción, transformación, distribución, comunicación pública, puesta a disposición, extracción, reutilización, reenvío o la utilización de cualquier naturaleza, por cualquier medio o procedimiento, de cualquiera de ellos, salvo en los casos en que esté legalmente permitido o sea autorizado por el titular de los correspondientes derechos.\n',
    legalRequirementsTextTen: 'Estas Condiciones Generales se rigen por la Ley española.\n',
    orderBook: 'Cartera de pedidos',
    readAndAccept: 'He Leído y Acepto',
    sales: 'Ventas',
    annualSalesHistory: 'Histórico ventas anual',
    firstAccessChangePassword: 'Para acceder por primera vez a la aplicación es necesario cambiar la contraseña',
    newPassword: 'Nueva Contraseña',
    password: 'Contraseña',
    repeatPassword: 'Repetir Contraseña',
    changePassword: 'Cambiar Password',
    passwordSuccessfullyChanged: 'Contraseña cambiada con exito.',
    unsecuredPassword: 'CopasswordMustBentraseña poco segura',
    passwordMustBe: 'La contraseña debe tener:',
    betweenCharacters: 'Entre 8 y 16 caracteres',
    leastOneDigit: 'Al menos un dígito',
    leastLowerAndUpperCase: 'Al menos una minúscula y al menos una mayúscula',
    promotionalExpenses: 'Gastos de promoción',
    averagePriceOfYourPurchases: 'Precio medio de sus compras',
    averagePrice: 'Precio Medio',
    closeLastPurchase: 'Fecha Última compra',
    mostPurchasedFamily: 'Familia más comprada',
    mostPurchasedFormats: 'Formatos más comprados',
    meters: 'Metros',
    activeClients: 'Clientes Activos',
    messageSentSuccessfully: 'Mensaje enviado con éxito',
    theMessageCouldNotBeSent: 'No se ha podido enviar el mensaje',
    noNewMessage: 'No hay nuevos mensajes',
    allYourAreas: 'Todas sus zonas',
    visitsZone: 'Visitas Zona',
    representatives: 'Representantes',
    noCustomers: 'No hay Clientes',
    aboutTheClient: 'Acerca del cliente',
    relations: 'Relaciones',
    addRelations: 'Agregar Relaciones',
    editRelation: 'Editar Relaciones',
    viewRelation: 'Ver Relaciones',
    allZones: 'Todas las zonas',
    selectALocation: 'Selecciona un Lugar',
    selectAnAgent: 'Selecciona un Agente',
    select: 'Seleccionar',
    addRelation: 'Agregar Relación',
    userList: 'Listar Usuarios',
    name: 'Nombre',
    nameZone: 'Nombre Zona',
    yourPosition: 'Tu posición',
    username: 'Nombre de Usuario',
    recoveryText: 'Te hemos enviado un link para el reseteo de la contraseña, revisa tu buzón.',
    familyName: 'Nombre de la familia',
    families: 'Familias',
    updatePassword: 'Actualizar Contraseña',
    comments: 'Comentarios',
    accumulatedBilling: 'Facturación acumulada',
    filters: 'Filtros',
    videowallPermissions: 'You do not have permission to access the videowall',
    noData: 'No hay datos',
    firstSurname: 'Primer Apellido',
    secondSurname: 'Segundo Apellido',
    unsafePassword: 'Contraseña poco segura',
    cancel: 'Cancelar',
    change: 'Cambiar',
    administrator: 'Administrador',
    createUser: 'Crear Usuario',
    promotion: 'Promoción',
    editRelations: 'Editar relación',
    totalOperational: 'OC operativas totales',
    nearbyOperational: 'OC operativas prox.',
    totalsOC: 'OC Totales ',
    sellingRate: 'Tipo de Venta',
    selectCompany: 'Seleccionar Compañia',
    confirmPassword: 'Confirmar Contraseña',
    selectRol: 'Seleccionar Rol',
    lastSevenDays: 'Últimos 7 días',
    apply: 'Aplicar',
    changeLanguage: 'Cambiar Idioma',
    year: 'Año',
    close: 'Cerrar',
    countries: 'Paises',
    all: 'Todos',
    quarterlyVariation: 'Variacion trimestral',
    monthlyVariation: 'Variación mensual',
    annualVariation: 'Variación anual',
    orderBookCurrent: 'Cartera de pedidos actual',
    accumulatedSquareMeters: 'Metros cuadrados acumulados',
    allCountries: 'Todos los paises',
    sync:'Sincronización',
    syncs:'Sincronizaciones',
    lastSync: 'Última Sincronización',
    labdays: 'Días Laborables',
    useApp: 'Uso de la App',
    selectACompany: 'Selecciona una compañía',
    currentValue: 'Valor mes actual',
    selectMonth: 'Selecciona el  mes',
    january:'Enero',
    february:'Febrero',
    march:'Marzo',
    april:'Abril',
    may: 'Mayo',
    june: 'Junio',
    july: 'Julio',
    august: 'Agosto',
    september: 'Septiembre',
    october: 'Octubre',
    november: 'Noviembre',
    december: 'Diciembre',
    general: 'General',
    mensajes: 'Mensajes',
    buscarZona: 'Buscar Zona...',
    buscarComercial: 'Buscar Comercial...',
    volver: 'Volver',
    darkmode: 'Modo Oscuro',
    buscarusuario: 'Buscar Usuario...',
    buscarcliente: 'Buscar Cliente...',
    home: 'Inicio',
    buscarrepresentant: 'Buscar Representante...',
    representant: 'Representante',
    nolocationtext: 'Los clientes no tienen datos de localización',
    sincs: 'Sincronizaciones',
    oksyncro: 'Última sincronización realizada con éxito',
    errorsyncro: 'Última sincronización fallida',
    doingsyncro: 'Ejecutando sincronización',
    completesyncro: 'Ejecutar Sincro. Completa',
    nosalessyncro: 'Ejecutar Sincro. Sin Ventas',
    salesdays: 'Días con ventas',
    usersaggregate: 'Agregado de Usuarios',
    buscarpor: 'Buscar por',
    rol: 'Rol',
    todos: 'TODAS',
    todas: 'TODOS',
    allgrouphours: 'Todas las Horas del Grupo',
    hoursbycompany: 'Horas por Empresa',
    zonehours: 'Horas de los Responsables de Zona',
    representanthours: 'Horas de los Representantes',
    devicehours: 'Horas por Dispositivo',
    allhours: 'Todas las horas',
    allhoursof: 'Todas las horas de ',
    pchours: 'Horas en PC',
    mobilehours: 'Horas en mobil',
    tablethours: 'Horas en tablet',
    userresume: 'Resumen del usuario',
    agent: 'Agente',
    zones: 'Zonas',
    edituser: 'Editar Usuario',
    newuser: 'Crear Usuario',
    recovery: 'Recuperar contraseña'
}

export default messages
