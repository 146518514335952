const messages = {
    forgottenPassword: 'Hai dimenticato la tua password?',
    group: 'Gruppo',
    customer: 'Cliente',
    period: 'periodo',
    monthly: 'Mensile',
    monthlyCapitalLetter: 'Mensile',
    month: 'Mese',
    quarterly: 'trimestrale',
    quarter: 'Quartiere',
    real: 'Reale',
    brands: 'Marchi',
    spanishProvinces: 'Province della Spagna',
    annual: 'Annuale',
    nextDayOC: 'Giorno successivo OC',
    exportCapitalLetter: 'ESPORTAZIONE',
    formats: 'Formati',
    superFamily: 'Super famiglia',
    year: 'Année',
    videowallPermissions: 'Non hai il permesso di accedere al video wall',
    companies: 'Edilizie',
    indicator: 'Indicatore',
    operationalAndPending: 'Operazioni e in attesa',
    hi: 'Ciao',
    averagePriceSimplified: 'PM',
    continents: 'Continenti',
    profile: 'Perfile',
    users: 'Utenti',
    logOut: 'Cancella la sottoscrizione',
    visits: 'visite',
    customers: 'Clienti',
    billing: 'Fatturazione',
    day: 'Giorno',
    accumulated: 'Accumulato',
    deliveryNotes: 'Bolle di consegna',
    deliveryNotesDay: 'Note di consegna del giorno',
    scope: 'Portata',
    variation: 'Variazione',
    averageePrice: 'prezzo medio',
    averageePriceLowerCase: 'Prezzo medio',
    value: 'Valore',
    last12Months: 'Ultimi 12 mesi',
    national: 'Nazionale',
    export: 'Esportazione',
    margin: 'Margine',
    total: 'Totale',
    orders: 'Ordini',
    portfolio: 'Portafoglio',
    current: 'Corrente',
    lastSynchronization: 'Ultimo aggiornamento',
    topGroups: 'Tops gruppo',
    appliedFilters: 'Filtri applicati',
    dimension: 'Dimensione',
    place: 'Posto',
    growth: 'Crescita',
    weight: 'Peso',
    unpaidYear: 'Anno non retribuito',
    commercial: 'Commerciale',
    company: 'Azienda',
    zone: 'Zona',
    delete: 'Elimina',
    relationDeleted: 'Relazione cancellata',
    edit: 'Modificare',
    unpaid: 'Non pagato',
    squareMeters: 'Metri quadri',
    article: 'articolo',
    active: 'attivo',
    appellants: 'Ricorrenti',
    new: 'Nuovi',
    loadingOrders: 'Ordini di caricamento',
    totals: 'Totales',
    next: 'Prossimo',
    today: 'Oggi',
    continent: 'Continente',
    country: 'Nazione',
    province: 'Provincia',
    howToGetThere: 'Come arrivare',
    writeMessage: 'Scrivi un messaggio...',
    legalRequirements: 'Condizioni legali',
    legalRequirementsTextOne: 'L\'uso dell\'applicazione del gruppo Pamesa e dei suoi servizi implica la lettura, la comprensione e l\'accettazione della presente nota legale da parte dell\'utente. ",',
    legalRequirementsTextTwo: '"In conformità con le disposizioni della legge 34/2002, dell\'11 luglio, sui servizi della società dell\'informazione e del commercio elettronico, sono riportati i seguenti aspetti',
    legalRequirementsTextThree: '- Pamesa Cerámica S.L. ha sede legale in Almazora, Camino Alcora numero 8, codice postale 12550. Per ulteriori informazioni è possibile contattarci tramite il numero di telefono 964 507 500 o il seguente indirizzo di posta elettronica nacional@pamesa.com oppure export@pamesa.com\',',
    legalRequirementsTextFour: '- Pamesa Cerámica S.L. è registrato nel registro mercantile di Castellón de la Plana, volume 469, libro 36, foglio 88, foglio CS 935, 1a registrazione, data 25 febbraio 1991 - N.I.F. B-12071486 - V.T.A - ESB-12071486.',
    legalRequirementsTextFive: ' - L\'Utente accetta volontariamente ed espressamente che l\'utilizzo di questa applicazione è in ogni caso sotto la sua unica ed esclusiva responsabilità.',
    legalRequirementsTextSix: '- Le credenziali assegnate a ciascun utente sono personali e non trasferibili, l\'utente è il solo responsabile delle conseguenze che possono derivare dall\'uso improprio, dalla divulgazione o dalla perdita degli stessi, nonché dal suo utilizzo da parte di un\'altra persona.',
    legalRequirementsTextSeven: '- Nell\'utilizzare l\'applicazione del Gruppo Pamesa, l\'Utente si impegna a non svolgere alcun comportamento che possa danneggiare l\'immagine, gli interessi e i diritti di Grupo Pamesa o di terzi o che possa danneggiarlo, disabilitarlo o sovraccaricarlo, o che impedire in alcun modo il suo normale utilizzo.',
    legalRequirementsTextEight: ' - I contenuti di Grupo Pamesa sono messi a disposizione dell\'utente da Pamesa Cerámica S.L. con informazioni provenienti da fonti proprie.',
    legalRequirementsTextNine: '- Attraverso le presenti Condizioni generali, nessun diritto di proprietà intellettuale o industriale viene trasferito sull\'applicazione di Grupo Pamesa o su uno qualsiasi dei suoi elementi componenti, essendo espressamente vietato all\'utente di riprodurre, trasformare, distribuire, comunicazione pubblica, rendere disponibile, estrarre, riutilizzo, inoltro o utilizzo di qualsiasi natura, con qualsiasi mezzo o procedura, di uno di essi, tranne nei casi in cui sia legalmente consentito o autorizzato dal proprietario dei diritti corrispondenti.',
    legalRequirementsTextTen: 'Le presenti Condizioni generali sono regolate dalla legge spagnola.',
    orderBook: 'Portafoglio ordini',
    readAndAccept: 'Ho letto e accetto',
    sales: 'Vendite',
    annualSalesHistory: 'Storico vendite annuali',
    firstAccessChangePassword: 'Per accedere all\'applicazione per la prima volta, è necessario modificare la password',
    newPassword: 'Nuova password',
    models: 'Modelli',
    europe: 'Europa',
    southOfAmerica: 'Sud America',
    centralOfAmerica: 'America Centrale',
    northOfAmerica: 'Nord America',
    asia: 'Asia',
    oceania: 'Oceania',
    africa: 'Africa',
    colors: 'Colori',
    allContinents: 'Tutti i continenti',
    password: 'Password',
    quality: 'Qualità',
    repeatPassword: 'Ripetere password',
    changePassword: 'Cambiare password',
    passwordSuccessfullyChanged: 'Password cambiata con successo',
    unsecuredPassword: 'Password non protetta',
    passwordMustBe: 'La password deve avere:',
    betweenCharacters: 'Una lunghezza tra gli e i 16 caratteri',
    leastOneDigit: 'Con almeno una cifra',
    leastLowerAndUpperCase: 'Con almeno una lettera maiuscola e una lettera minuscola',
    promotionalExpenses: 'Spese promozionale',
    averagePriceOfYourPurchases: 'prezzo di acquisto medio',
    averagePrice: 'Prezzo medio',
    closeLastPurchase: 'L\'ultima datta di acquisto',
    mostPurchasedFamily: 'Finitura più acquistata',
    mostPurchasedFormats: 'Formati più acquistati',
    meters: 'Metri',
    activeClients: 'Clienti attivi',
    messageSentSuccessfully: 'Il messaggio è stato inviato con successo',
    theMessageCouldNotBeSent: 'Non è stato possibile inviare il messaggio',
    noNewMessage: 'Non ci sono nuovi messaggi',
    allYourAreas: 'Tutti le sue zone',
    visitsZone: 'Visite zona',
    representatives: 'Rappresentanti',
    noCustomers: 'Non ci sono clienti',
    aboutTheClient: 'Circa il cliente',
    relations: 'Rapporti',
    addRelations: 'Aggiungere rapporti',
    editRelation: 'Modificare rapporti',
    viewRelation: 'Vedere rapporti',
    allZones: 'Tutte le zone',
    selectALocation: 'Scegli un posto',
    selectAnAgent: 'Scegli un agente',
    select: 'Scegliere',
    addRelation: 'Aggiungi rapporto',
    userList: 'Elencare consumatori',
    name: 'Nome',
    nameZone: 'Nome Zonaa',
    yourPosition: 'La tua posizione',
    username: 'Nome utente',
    recoveryText: 'Ti abbiamo inviato un link per reimpostare la password, controllare la tua casella di posta.',
    familyName: 'Nome della famiglia',
    families: 'Famiglia',
    updatePassword: 'Aggiornare password',
    comments: 'Osservazione',
    accumulatedBilling: 'Fatturazione raccolta',
    filters: 'Filtri',
    noData: 'Non ci sono datti',
    firstSurname: 'Cognome',
    secondSurname: 'Secondo nome',
    unsafePassword: 'Scelta di password poco sicure',
    cancel: 'Cancellare',
    change: 'Modificare',
    administrator: 'Amministratore',
    createUser: 'Crea utente',
    promotion: 'Promozione',
    totalOperational: 'Totale ordini di carrico operativi',
    nearbyOperational: 'Prossime ordini di carrico operativi',
    totalsOC: 'Totale ordini di carrico',
    sellingRate: 'Tipo di vendita',
    selectCompany: 'Scegliete società',
    confirmPassword: 'Conferma password',
    selectRol: 'Selezionare parte',
    lastSevenDays: 'Ultimi 7 giorni',
    apply: 'Richiedi',
    changeLanguage: 'Cambiare lingua',
    close: 'Chiudere',
    countries: 'Paesi',
    all: 'Tutti',
    quarterlyVariation: 'Variazione trimestrale',
    monthlyVariation: 'Variazione mensile',
    annualVariation: 'Variazione annuale',
    orderBookCurrent: 'Portafoglio degli ordini attuali',
    accumulatedSquareMeters: 'Metri quadri accumulati',
    allCountries: 'Tutti i paesi',
    sync:'Sincronizzazione',
    syncs:'Sincronizzazioni',
    lastSync: 'Ultima sincronizzazione',
    labdays: 'Giorni di lavoro',
    useApp: "Utilizzo dell'applicazione",
    currentValue: 'Valore del mese corrente',
    selectMonth: 'Seleziona il mese',
    january:'Gennaio',
    february:'Febbraio',
    march:'Marzo',
    april:'Aprile',
    may: 'Maggio',
    june: 'Giugno',
    july: 'luglio',
    august: 'Agosto',
    september: 'Settembre',
    october: 'Ottobre',
    november: 'Novembre',
    december: 'Dicembre',
    general: 'Generale',
    mensajes: 'Messaggi',
    buscarZona: 'Cercare una Zona...',
    buscarComercial: 'Cercare Commerciale...',
    volver: 'Ritorno',
    darkmode: 'Modalità scura',
    buscarusuario: 'Cerca Utenti...',
    buscarcliente: 'Cercare Cliente...',
    home: 'Inizio',
    buscarrepresentant: 'Trova un Rappresentante...',
    representant: 'Rappresentante',
    nolocationtext: "I clienti non dispongono di dati sulla posizione",
    sincs: 'Sincronizzazioni',
    oksyncro: 'Ultima sincronizzazione riuscita',
    errorsyncro: "L'ultima sincronizzazione non è riuscita",
    doingsyncro: 'Sincronizzazione in corso',
    completesyncro: 'Esegui la sincronizzazione. Completare',
    nosalessyncro: 'Esegui la sincronizzazione. Nessuna vendita',
    salesdays: 'Giornate con i saldi',
    usersaggregate: "Aggiunta di utenti",
    buscarpor: 'Ricerca per',
    rol: 'Ruolo',
    todos: 'TUTTO',
    todas: 'TUTTO',
    allgrouphours: 'Tutti gli orari dei gruppi',
    hoursbycompany: 'Ore per azienda',
    zonehours: 'Orari dei gestori di zona',
    representanthours: 'Orari di rappresentanza',
    devicehours: 'Ore per dispositivo',
    allhours: 'Tutte le ore',
    allhoursof: 'Tutte le ore di ',
    pchours: 'Ore su PC',
    mobilehours: 'Ore sul cellulare',
    tablethours: 'Ore sul tablet',
    userresume: 'Riepilogo utente',
    agent: 'Agente',
    zones: 'Zone',
    edituser: 'Modifica utente',
    newuser: 'Crea utente',
    recovery: 'Recuperare la password'
}

export default messages
