import Model from "../Model";

class Auth extends Model {
    buildUrl ({ action }) {
        return [action];
    }

    login(form) {

        return this.request({
            action: 'login',
            method: 'POST',
            data: form
        });
    }

    logout() {
        return this.request({
            action: 'logout',
            method: 'POST'
        });
    }

    me() {
        return this.request({
            action: 'me',
            method: 'POST'
        });
    }

    recoverypass(form){
        return this.request({
            action: 'password/email',
            method: 'POST',
            data: form
        })
    }
    passwordresset(form){
        return this.request({
            action: 'password/reset',
            method: 'POST',
            data: form
        })
    }

    register(form){
        return this.request({
            action: 'user/activate',
            method: 'POST',
            data: form
        })
    }
};

export default new Auth;