import Model from "../Model";

class DeleteRelation extends Model{

    buildUrl ({ param }) {
        return ['userrelation/delete', param];
    }

    all(config = {}) {
        return this.request({ method: 'POST', action: 'all', ...config });
    }

    delete(form) {
        return this.request({
            action: 'destroy',
            method: 'POST',
            data: form
        });
    }
}


export default new DeleteRelation;