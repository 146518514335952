import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';

import { Auth } from '@/api/models';
import {routeByRole, routeByUser} from './helpers';
import { state, actions } from '@/store';

import axios from 'axios';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: routes(authGuard, guestGuard)
});

export default router;

router.beforeEach(async (to, from, next) => {
    if (state.token && !state.user) {
        
        const user = await Auth.me();
        actions.setUser(user);
    }


    if (state.user) {
        window.dataLayer.push({
            event: 'EventoTdC',
            id: state.user?.id,
            section: to.name,
        });
    }

    // axios.get(process.env.VUE_APP_BASEURL + '/register', {}, { headers: {'Authorization': `Bearer ${ state.token }`}}).then(response => {

    //     console.log(response)

    // })

    if(state.token){

        const instance = axios.create({
            baseURL: process.env.VUE_APP_BASEURL,
            headers: {'Authorization': `Bearer ${ state.token }`}
        });
        
        instance.get('/register')
        .then(response => {
            
            actions.setToken(response.data.data)

            setTimeout(() => {
                next();
            }, 100);

        }).catch(function (error){
            
            if(error.response.status == 401){
                actions.resetAuth();
                window.location.reload();
            }

        })

    } else {
        next();
    }

});

function beforeEnter (routes, callback) {
    return routes.map(route => {
        return { ...route, beforeEnter: callback };
    });
};

function authGuard (routes) {
    return beforeEnter(routes, (to, from, next) => {
        if (!state.token) {
            return next({ name: 'login' });
        }

        if (state.user.firstlogin && to.name !== 'accept') {
            next({ name : 'accept' });
        } else if (state.user.forcepassword && !state.user.firstlogin && to.name !== 'changepass') {
            next({ name : 'changepass' });
        } else {
            next();
        }
    });
};

function guestGuard (routes) {
    return beforeEnter(routes, (to, from, next) => {
        if (state.token) {
            const route = routeByUser(state.user);
            next(route);
        }

        next();
    });
};


















