const messages = {
    forgottenPassword: 'Haben Sie Ihr Passwort vergessen?',
    customer: 'Kunde',
    group: 'Gruppe',
    customer: 'Kunde',
    period: 'Zeitraum',
    brands: 'Marken',
    monthly: 'Monatlich',
    monthlyCapitalLetter: 'Monatlich',
    month: 'Monat',
    europe: 'Europe',
    southOfAmerica: 'Südamerika',
    centralOfAmerica: 'Mittelamerika',
    northOfAmerica: 'Nordamerika',
    asia: 'Asien',
    videowallPermissions: 'Sie haben keine Berechtigung zum Zugriff auf die Videowand',
    oceania: 'Ozeanien',
    africa: 'Afrika',
    quarterly: 'Vierteljährlich',
    quarter: 'Quartal',
    annual: 'Jährlich',
    allContinents: 'Alle Kontinente',
    operationalAndPending: 'Operationen und anhängig',
    averagePriceSimplified: 'DP',
    companies: 'Unternehmen',
    indicator: 'Indikator',
    hi: 'Hallo',
    profile: 'Profil',
    users: 'Benutzer',
    logOut: 'Ausloggen',
    visits: 'Besuche',
    customers: 'Kunden',
    billing: 'Abrechnung',
    day: 'Tag',
    accumulated: 'Angesammelt',
    deliveryNotes: 'Lieferscheine',
    deliveryNotesDay: 'Lieferscheine des Tages',
    scope: 'Umfang',
    variation: 'Variation',
    averagePrice: 'zum halben Preis',
    averagePriceLowerCase: 'Zum halben Preis',
    value: 'Wert',
    last12Months: 'Letzten 12 Monate',
    national: 'National',
    export: 'Export',
    margin: 'Spanne',
    total: 'Gesamt',
    orders: 'Bestellungen',
    portfolio: 'Börse',
    current: 'Aktuell',
    lastSynchronization: 'letzte Aktualisierung',
    topGroups: 'Spitzengruppe',
    appliedFilters: 'Filter angewendet',
    dimension: 'Abmessung',
    place: 'Ort',
    growth: 'Wachstum',
    weight: 'Gewicht',
    unpaidYear: 'Unbezahltes Jahr',
    commercial: 'kommerziell',
    company: 'Unternehmen',
    zone: 'Zone',
    delete: 'Löschen',
    relationDeleted: 'Beziehung gelöscht',
    edit: 'Bearbeiten',
    unpaid: 'Unbezahlt',
    squareMeters: 'm2',
    article: 'Artikel',
    active: 'Aktiv',
    appellants: 'Wiederkehrend',
    new: 'Neu',
    loadingOrders: 'Bestellungen zu laden',
    totals: 'Gesamt',
    next: 'Nächstes',
    today: 'Heute',
    continent: 'Kontinent',
    continents: 'Kontinente',
    superFamily: 'Super-Familie',
    spanishProvinces: 'Provinzen in Spanien',
    country: 'Land',
    formats: 'Formate',
    models: 'Modelle',
    province: 'Stadt',
    colors: 'Farben',
    howToGetThere: 'Wie komme ich nach…',
    writeMessage: 'Schreiben Sie eine Nachricht',
    legalRequirements: 'Rechtliche Bedingungen',
    legalRequirementsTextOne: 'Die Nutzung der Pamesa Group-Anwendung sowie ihrer Dienste setzt voraus, dass der Benutzer diesen rechtlichen Hinweis liest, versteht und akzeptiert.',
    legalRequirementsTextTwo: 'In Übereinstimmung mit den Bestimmungen des Gesetzes 34/2002 vom 11. Juli über Dienstleistungen der Informationsgesellschaft und des elektronischen Geschäftsverkehrs hat die',
    legalRequirementsTextThree: '- - Pamesa Cerámica S.L. hat seinen eingetragenen Sitz in Almazora, Camino Alcora Nummer 8 und Postleitzahl 12550. Für weitere Informationen können Sie uns telefonisch unter 964 507 500 oder per E-Mail unter nacional@pamesa.com oder export@pamesa.com kontaktieren,',
    legalRequirementsTextFour: '- Pamesa Cerámica S.L. ist im Handelsregister von Castellón de la Plana, Band 469, Buch 36, Folio 88, Blatt CS 935, 1. Registrierung, Datum 25. Februar 1991 - N.I.F. B-12071486 - V.T.A. - ESB-12071486.',
    legalRequirementsTextFive: ' - Der Benutzer akzeptiert freiwillig und ausdrücklich, dass die Nutzung dieser Anwendung in jedem Fall in seiner alleinigen und ausschließlichen Verantwortung liegt,',
    legalRequirementsTextSix: '- Die jedem Benutzer zugewiesenen Anmeldeinformationen sind persönlich und nicht übertragbar, wobei der Benutzer allein für die möglicherweise auftretenden Konsequenzen verantwortlich ist',
    legalRequirementsTextSeven: '- Mit der Nutzung der Grupo Pamesa-Anwendung verpflichtet sich der Benutzer, keine Verhaltensweisen auszuführen, die das Image, die Interessen und Rechte von Grupo Pamesa oder Dritten beschädigen oder diese beschädigen, deaktivieren oder überlasten oder verhindern könnten in jedem Fall die normale Verwendung davon.',
    legalRequirementsTextEight: ' - Der Inhalt der Grupo Pamesa wird dem Benutzer von Pamesa Cerámica S.L. mit Informationen aus eigenen Quellen dargestellt.',
    legalRequirementsTextNine: '- Durch diese Allgemeinen Geschäftsbedingungen werden keine Rechte an geistigem oder gewerblichem Eigentum über die Anwendung von Grupo Pamesa oder über irgendwelche übertragen',
    legalRequirementsTextTen: 'Diese Allgemeinen Geschäftsbedingungen unterliegen spanischem Recht.',
    orderBook: 'Auftragsbuch',
    readAndAccept: 'Ich habe gelesen und akzeptiert',
    sales: 'Der Umsatz',
    annualSalesHistory: 'Jahresumsatzrekord',
    firstAccessChangePassword: 'Um zum ersten Mal auf die Anwendung zuzugreifen, muss das Passwort geändert werden',
    newPassword: 'Neues Passwort',
    password: 'Passwort',
    repeatPassword: 'Passwort wiederholen',
    changePassword: 'Passwort ändern',
    passwordSuccessfullyChanged: 'Passwort mit Erfolg geändert',
    unsecuredPassword: 'Passwort unsicher',
    passwordMustBe: 'Das Passwort muss haben…',
    betweenCharacters: 'zwischen 8 und 16 Zeichen',
    leastOneDigit: 'Zimundest eine Ziffer',
    leastLowerAndUpperCase: 'Zumindest ein Kleinbuchstabe und ein Grossbuchstabe',
    promotionalExpenses: 'Werbekosten',
    aboutTheClient: 'Über den Auftraggeber',
    averagePriceOfYourPurchases: 'Durchschnittspreise Ihrer Einkäufe',
    closeLastPurchase: 'Schließen Letzter Kauf',
    mostPurchasedFormats: 'Meist verkaufte Formate',
    mostPurchasedFamily: 'Meist verkaufte Familie',
    meters: 'Meters',
    nextDayOC: 'Nächster Tag OK',
    exportCapitalLetter: 'AUSFUHR',
    activeClients: 'Aktive Kunden',
    messageSentSuccessfully: 'Nachricht mit Erfolg gesendet',
    theMessageCouldNotBeSent: 'Nachricht wurde nicht gesendet',
    noNewMessage: 'Keine neue Nachrichten',
    allYouraAreas: 'Alle Ihre Zonen',
    visitsZone: 'Besuchszonen',
    representatives: 'Vertreter',
    noCustomers: 'Es gibt keine Kunden',
    relations: 'Beziehungen',
    addRelations: 'Beziehungen ergänzen',
    editRelations: 'Beziehungen bearbeiten',
    viewRelations: 'Beziehungen sehen',
    allZones: 'Alle Zonen',
    selectALocation: 'Wählen Sie einen Ort',
    selectAnAgent: 'Wählen Sie einen Vertreter',
    select: 'Auswählen',
    addRelation: 'Beziehung ergänzen',
    userList: 'Benutzer listen',
    apply: 'Anwenden',
    name: 'Name',
    nameZone: 'Zonenname',
    yourPosition: 'Ihre Position',
    theBest: 'Die Besten',
    username: 'Benutzername',
    recoveryText: 'Wir haben Ihnen einen Link geschickt, überprüfen Sie Ihr',
    familyName: 'Familienname',
    families: 'Familienname',
    updatePassword: 'Passwort aktualisieren',
    comments: 'Kommentare',
    accumulatedBilling: 'kumulierte Abrechnung',
    filters: 'Filter',
    noData: 'Keine Daten',
    firstSurname: 'Vorname',
    secondSurname: 'Nachname',
    unsafePassword: 'Passwort nicht sicher',
    cancel: 'Stornieren',
    change: 'ändern',
    administrator: 'Administrator',
    createUser: 'Benutzer erstellen',
    promotion: 'Bemusterung',
    totalOperational: 'Gesamtladeorder operativ',
    nearbyOperational: 'Nächste operative Ladeorder',
    totalsOC: 'Gesamtladeorder',
    sellingRate: 'Verkaufsrate',
    selectCompany: 'Wählen Sie ein Unternehmen',
    confirmPassword: 'Passwort bestätigen',
    selectRol: 'Wählen Sie Rol',
    lastSevenDays: 'Letzten 7 Tage',
    changeLanguage: 'Sprache ändern',
    year: 'Jahr',
    close: 'schließen',
    countries: 'Länder',
    quality: 'Qualität',
    all: 'Alle',
    quarterlyVariation: 'Quartal Variation',
    monthlyVariation: 'Monatliche Variation',
    annualVariation: 'Jährliche Variation',
    orderBookCurrent: 'Aktuelle Ladeliste',
    accumulatedSquareMeters: 'Gesamter Qm',
    allCountries: 'Alle Länder',
    sync:'Synchronisation',
    syncs:'Synchronisationen',
    lastSync: 'Letzte Synchronisation',
    labdays: 'Arbeitstage',
    useApp: 'Verwenden der App',
    currentValue: 'Tapferkeit ist echt',
    selectMonth: 'Wählen Sie den Monat aus',
    january:'Januar',
    february:'Februar',
    march:'Marsch',
    april:'April',
    may: 'Mai',
    june: 'Juni',
    july: 'Juli',
    august: 'August',
    september: 'September',
    october: 'Oktober',
    november: 'November',
    december: 'Dezember',
    general: 'Allgemein',
    mensajes: 'Mitteilungen',
    buscarZona: 'Suche nach einem Bereich...',
    buscarComercial: 'Suchen Sie nach einem Werbespot...',
    volver: 'Zurückkehren',
    darkmode: 'Dunkelmodus',
    buscarusuario: 'Benutzer Suchen...',
    buscarcliente: 'Suche einen Kunden...',
    home: 'Heim',
    buscarrepresentant: 'Finden Sie einen Vertreter...',
    representant: 'Vertreter',
    nolocationtext: 'Kunden verfügen über keine Standortdaten',
    sincs: 'Synchronisierungen',
    oksyncro: 'Letzte erfolgreiche Synchronisierung',
    errorsyncro: 'Die letzte Synchronisierung ist fehlgeschlagen',
    doingsyncro: 'Synchronisierung ausgeführt',
    completesyncro: 'Führen Sie die Synchronisierung aus. Vollständig',
    nosalessyncro: 'Führen Sie die Synchronisierung aus. Keine Verkäufe',
    salesdays: 'Tage mit Verkauf',
    usersaggregate: 'Benutzer hinzufügen',
    buscarpor: 'Suche mit',
    rol: 'Rolle',
    todos: 'ALLE',
    todas: 'ALLE',
    allgrouphours: 'Alle Gruppenzeiten',
    hoursbycompany: 'Stunden pro Unternehmen',
    zonehours: 'Öffnungszeiten der Zonenmanager',
    representanthours: 'Repräsentative Öffnungszeiten',
    devicehours: 'Stunden pro Gerät',
    allhours: 'Alle Stunden',
    allhoursof: 'Alle Öffnungszeiten von ',
    pchours: 'Stunden auf dem PC',
    mobilehours: 'Stunden auf dem Handy',
    tablethours: 'Stunden auf dem Tablet',
    userresume: 'Benutzerzusammenfassung',
    agent: 'Agent',
    zones: 'Zonen',
    edituser: 'Benutzer bearbeiten',
    newuser: 'Benutzer erstellen',
    recovery: 'Passwort wiederherstellen'
}

export default messages
