const messages = {
    forgottenPassword: 'Vous avez oublié votre mot de passe?',
    customer: 'Client',
    group: 'Groupe',
    period: 'Période',
    monthly: 'Mensuel',
    customer: 'Client',
    monthlyCapitalLetter: 'Mensuel',
    month: 'Mois',
    brands: 'Marques',
    quarterly: 'Trimestriel',
    quarter: 'Trimestre',
    operationalAndPending: 'Opérations et en cours',
    annual: 'Annuel',
    nextDayOC: 'Jour suivant OC',
    exportCapitalLetter: 'EXPORTATION',
    videowallPermissions: 'Vous n\'avez pas la permission d\'accéder au mur vidéo',
    averagePriceSimplified: 'PM',
    year: 'Année',
    companies: 'Entreprises',
    indicator: 'Indicateur',
    hi: 'Bonjour',
    profile: 'Profil',
    users: 'Usagers',
    logOut: 'Fermer cession',
    visits: 'Visites',
    continents: 'Continents',
    customers: 'Clients',
    billing: 'Facturation',
    day: 'Jour',
    accumulated: 'Accumulé',
    deliveryNotes: 'Bons de livraisons',
    deliveryNotesDay: 'BL du jour',
    scope: 'Projection',
    variation: 'Variation',
    averagePrice: 'Prix moyen',
    averagePriceLowerCase: 'Prix moyen',
    value: 'Valeur',
    last12Months: '12 derniers mois',
    national: 'National',
    export: 'Exportation',
    margin: 'Marge',
    total: 'Total',
    orders: 'Commandes',
    portfolio: 'Portefeuille',
    current: 'actuel',
    lastSynchronization: 'Dernière actualisation',
    topGroups: 'Top Groupe',
    appliedFilters: 'Filtres appliqués',
    dimension: 'dimension',
    place: 'Lieu',
    growth: 'Croissance',
    weight: 'Poids',
    unpaidYear: 'Impayés annuel',
    commercial: 'Commerciaux',
    company: 'Société',
    zone: 'Zone',
    delete: 'Effacer',
    relationDeleted: 'Relation effaçée',
    edit: 'Éditer',
    unpaid: 'Impayé',
    squareMeters: 'Mètres carrés',
    article: 'Articles',
    active: 'Actif',
    appellants: 'Récurrents',
    new: 'Nouveaux',
    loadingOrders: 'Ordres de chargements',
    totals: 'Totaux',
    next: 'Prochain',
    today: 'Aujourd\'hui',
    continent: 'Contenu',
    country: 'Pays',
    formats: 'Formats',
    models: 'Modèles',
    province: 'Département',
    europe: 'Europe',
    southOfAmerica: 'Amérique du Sud',
    centralOfAmerica: 'Amérique centrale',
    northOfAmerica: 'Amérique du Nord',
    asia: 'Asie',
    oceania: 'Océanie',
    africa: 'Afrique',
    howToGetThere: 'Comment arriver',
    spanishProvinces: 'Provinces d\'Espagne',
    allContinents: 'Tous les continents',
    quality: 'Qualité',
    colors: 'Couleurs',
    superFamily: 'Super Famille',
    writeMessage: 'Écriver un message...',
    legalRequirements: 'Conditions légales',
    legalRequirementsTextOne: 'L\'utilisation de l\'application du Groupe Pamesa ainsi que de tous ses services, implique la lecture, compréhension et acceptation du présent avis légal de la part de l\'usager.',
    legalRequirementsTextTwo: 'En application de ce qu\'établit la Loi 34/2002, du 11 juillet, des Services de la Sociéte d\'Information et Commerce Electronique, nous vous informons des suivants aspects:',
    legalRequirementsTextThree: '- Pamesa Ceramica SL a son siège social à Almazora, Cno Alcora, nº 8, code postal 12550. Pour plus d\'information, vous pouvez nous contacter au nº de téléphone 964 507 500, ou bien aux adresses mails suivantes : nacional@pamesa.com ou export@pamesa.com,',
    legalRequirementsTextFour: '- Pamesa Cerámica S.L. est inscrite au Registre du Commerce de Castellon de la Plana, Tomo 469, Libro 36, Folio 88, Hoja CS 935, Inscription 1ª, Date 25.02.1991 – N.I.F. B-12071486 – V.T.A – ESB-12071486.\\n\',',
    legalRequirementsTextFive: ' - L\'usager accepte volontairement et expréssement que l\'usage de cette application se réalise en tous cas sous son unique et entière responsabilité.',
    legalRequirementsTextSix: '- Les pouvois assignés à chaque usager sont personnels et non transmissibles, l\'usager étant le seul responsable des conséquences qui puissent dériver d\'un mauvais usage divulgation ou perte des mêmes tout comme l\'usage par un tiers.',
    legalRequirementsTextSeven: '- Dans l\'utilisation de l\'application de Groupe Pamesa, l\'usager s\'engage à ne pas avoir de comportement qui puisse nuie à l\'image, aux intérêts et aux droits du Groupe Pamesa oou de tiers qui pourrait l\'endommager, l\'inutiliser, la surcharger ou empêcher, de quelques formes que ce soit, son utilisation normale.',
    legalRequirementsTextEight: ' - Les contenus du Groupe Pamesa son mis à disposition des usagers par Pamesa Ceramica SL avec des informations provenant de ces propres sources.',
    legalRequirementsTextNine: '- Au travers des Conditions Générales, il n\'est cédé aucun droit de propriété intélectuelle ni industriel sur l\'application du Groupe Pamesa ni sur aucun élément intégrant, il est expréssément interdit à l\'usager de reproduire, transformer, distribuer, de divulguer, mettre à disposition, extraire, réutiliser, renvoyer ou utiliser de quelques formes que ce soit, au travers de tout type de moyen ou procédé, quels qu\'ils soient, sauf dans les cas ou ils soient légalement autorisés par le titulaire des droits correspondants.',
    legalRequirementsTextTen: 'Ces Conditions Générales sont régies par la Loi Espagnole.',
    orderBook: 'Portefeuille commandes',
    readAndAccept: 'Lu et approuvé',
    sales: 'Ventes',
    annualSalesHistory: 'Historiques ventes annuelles',
    firstAccessChangePassword: 'Pour accéder la 1ère fois à l\'application, il est nécessaire de modifier',
    newPassword: 'Nouveau mot de passe',
    password: 'Mot de passe',
    repeatPassword: 'Répéter mot de passe',
    changePassword: 'Modifier mot de passer',
    passwordSuccessfullyChanged: 'Mot de passe modifié avec succès',
    unsecuredPassword: 'Mot de passe peu sûr',
    passwordMustBe: 'Le mot de passe doit avoir:',
    betweenCharacters: 'Entre 8 et 16 caractères',
    leastOneDigit: 'Au moins un chiffre',
    leastLowerAndUpperCase: 'Au moins une minuscle et une majuscule',
    promotionalExpenses: 'Frais de promotion',
    averagePriceOfYourPurchases: 'Prix moyen des achats',
    closeLastPurchase: "Dernière date d'achat",
    mostPurchasedFamily: 'Famille la plus achetée',
    mostPurchasedFormats: 'Formats les plus achetés',
    meters: 'Mètres',
    activeClients: 'Clients actifs',
    messageSentSuccessfully: 'Message envoyé avec succès',
    theMessageCouldNotBeSent: "le message n'a pu être envoyé",
    noNewMessage: "Il n'y a pas de nouveaux messages",
    allYourAreas: 'Tous vos secteurs',
    visitsZone: 'Visites secteurs',
    representatives: 'Représentants',
    noCustomers: "Il n'y a pas de clients",
    aboutTheClient: 'Au sujet des clients',
    relations: 'Relations',
    addRelations: 'Ajuoter relations',
    editRelation: 'Editer relations',
    viewRelation: 'Voir relations',
    allZones: 'Tous vos secteurs',
    selectALocation: 'Sélectionner un lieu',
    selectAnAgent: 'Sélectionner un agent',
    select: 'Sélectionner',
    addRelation: 'Ajouter relation',
    userList: 'Lister les usagers',
    name: 'Noms',
    nameZone: 'Nom secteur',
    yourPosition: 'Votre position',
    username: "Nom de l'usager",
    recoveryText: 'Nous vous avons envoyer un lien pour reséter le mot de passe, contrôle',
    familyName: 'Nom de la famille',
    families: 'Famille',
    updatePassword: 'Actualiser mot de passe',
    comments: 'Commentaires',
    accumulatedBilling: 'Facturation accumulée',
    filters: 'Filtres',
    noData: 'Pas de données',
    firstSurname: 'Premier nom de famille o 1er nom de famille',
    secondSurname: 'Seuxième nom de famille o 2ème nom de famille',
    unsafePassword: 'Mot de passe peu sûr',
    cancel: 'Annuler',
    change: 'Modifier',
    administrator: 'Administrateur',
    createUser: 'Créer usager',
    promotion: 'Promotion',
    totalOperational: 'OC opératives totales',
    nearbyOperational: 'OC opératives prochaines',
    totalsOC: 'Totaux du CO',
    sellingRate: 'Type de vente',
    selectCompany: 'Sélectionner la société',
    confirmPassword: 'Confirmer mot de passe',
    selectRol: 'Sélectionner Rol',
    lastSevenDays: 'derniers 7 jours',
    apply: 'Appliquer',
    changeLanguage: 'Changer la langue',
    close: 'Fermer',
    countries: 'Pays',
    all: 'Tous',
    quarterlyVariation: 'Variation trimestrielle',
    monthlyVariation: 'Variation mensuelle',
    annualVariation: 'Variation annuelle',
    orderBookCurrent: 'Portefeuille de commande actuel',
    accumulatedSquareMeters: 'M2 cumulés',
    allCountries: 'Tous les pays',
    sync:'Synchronisation',
    syncs:'Les synchronisations',
    lastSync: 'Dernière synchronisation',
    labdays: 'Jours de travail',
    useApp: "Utilisation de l'appli",
    currentValue: 'Valeur du mois en cours',
    selectMonth: 'Sélectionnez le mois',
    january:'Janvier',
    february:'Février',
    march:'Mars',
    april:'Avril',
    may: 'Mai',
    june: 'Juin',
    july: 'Juillet',
    august: 'Août',
    september: 'Septembre',
    october: 'Octobre',
    november: 'Novembre',
    december: 'Décembre',
    general: 'Général',
    mensajes: 'Messages',
    buscarZona: 'Rechercher une Zone...',
    buscarComercial: 'Recherche Commerciale...',
    volver: 'Revenir',
    darkmode: 'Mode Sombre',
    buscarusuario: 'Rechercher des Utilisateursn...',
    buscarcliente: 'Chercher Client...',
    home: 'Maison',
    buscarrepresentant: 'Trouver un Représentant...',
    representant: 'Représentant',
    nolocationtext: "Les clients n'ont pas de données de localisation",
    sincs: 'Synchronisations',
    oksyncro: 'Dernière synchronisation réussie',
    errorsyncro: 'La dernière synchronisation a échoué',
    doingsyncro: "Synchronisation en cours d'exécution",
    completesyncro: 'Exécutez la synchronisation. Complet',
    nosalessyncro: 'Exécutez la synchronisation. Aucune vente',
    salesdays: 'Jours de soldes',
    usersaggregate: "Ajout d'utilisateurs",
    buscarpor: 'Rechercher par',
    rol: 'Rôle',
    todos: 'TOUS',
    todas: 'TOUTES',
    allgrouphours: 'Toutes les heures de groupe',
    hoursbycompany: 'Heures par entreprise',
    zonehours: 'Heures des gestionnaires de zone',
    representanthours: 'Heures de représentation',
    devicehours: 'Heures par appareil',
    allhours: 'Toutes les heures',
    allhoursof: 'Toutes les heures de ',
    pchours: 'Heures sur PC',
    mobilehours: 'Heures sur mobile',
    tablethours: 'Heures sur tablette',
    userresume: "Résumé de l'utilisateur",
    agent: 'Agent',
    zones: 'Zones',
    edituser: "Modifier l'utilisateur",
    newuser: 'Créer un utilisateur',
    recovery: 'Récupérer le mot de passe'
    
}

export default messages
