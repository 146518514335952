import Auth from './Auth';
import Groupview from './Groupview';
import Groupcompanies from './Groupcompanies';
import Groupdimensions from './Groupdimensions';
import Company from './Company';
import Groupcompanydetails from './Groupcompanydetails';
import Comercialsbytype from './Comercialsbytype';
import Representantbubbles from './Representantbubbles';
import Companydetails from './Companydetails';
import Changeme from './Changeme';
import KpiComercialGraphsUser from './KpiComercialGraphsUser';
import KpiRepresentantebyuser from './KpiRepresentantebyuser';
import KpiRepresentantebyzone from './KpiRepresentantebyzone';
import KpiRepresentantegraphsuser from './KpiRepresentantegraphsuser';
import KpiRepresentantegraphszone from './KpiRepresentantegraphszone';
import ComercialUser from './ComercialUser';
import ComercialZone from './ComercialZone';
import Companybubbledetails from './Companybubbledetails';
import ComercialInfoUser from './ComercialInfoUser';
import ComercialInfoZone from './ComercialInfoZone';
import RepresentantsUser from './RepresentantsUser';
import RepresentantsZone from './RepresentantsZone';
import Groupinfo from './Groupinfo';
import Companyinfo from './Companyinfo';
import KpiComercialGraphsZone from './KpiComercialGraphsZone';
import KpiComercialUser from './KpiComercialUser';
import KpiComercialZone from './KpiComercialZone';
import KpiEmployeeUser from './KpiEmployeeUser';
import KpiEmployeeZone from './KpiEmployeeZone';
import KpiGraphsComercialClientUser from './KpiGraphsComercialClientUser';
import KpiGraphsComercialClientZone from './KpiGraphsComercialClientZone';
import KpiGraphsComercialClientUser2 from './KpiGraphsComercialClientUser2';
import KpiGraphsComercialClientZone2 from './KpiGraphsComercialClientZone2';
import ComercialbubbledetailUser from './ComercialbubbledetailUser';
import ComercialbubbledetailZone from './ComercialbubbledetailZone';
import ComercialVisitsZone from './ComercialVisitsZone';
import RepresentantbubblesZone from './RepresentantbubblesZone';
import Representantbubblesdetailuser from './Representantbubblesdetailuser';
import ClientsEmployeeUser from './ClientsEmployeeUser';
import ClientsEmployeeZone from './ClientsEmployeeZone';
import KpiclientrepresentantUser from './KpiclientrepresentantUser';
import KpiclientrepresentantZone from './KpiclientrepresentantZone';
import KpiclientrepresentantUser2 from './KpiclientrepresentantUser2';
import KpiclientrepresentantZone2 from './KpiclientrepresentantZone2';
import RepresentantsviewsZone from './RepresentantsviewsZone';
import RepresentantsviewsUser from './RepresentantsviewsUser';
import Grouptops from './Grouptops';
import Continenttops from './Continenttops';
import Countrytops from './Countrytops';
import Provincetops from './Provincetops';
import Dimensionfiltertops from './Dimensionfiltertops';
import Comercialidtops from './Comercialidtops';
import Comercialzonetops from './Comercialzonetops';
import Representantidtops from './Representantidtops';
import Representantzonetops from './Representantzonetops';
import Comercialclientidtops from './Comercialclientidtops';
import Comercialclientzonetops from './Comercialclientzonetops';
import Representantclientidtops from './Representantclientidtops';
import Representantclientzonetops from './Representantclientzonetops';
import Grouptocompanymessage from './Grouptocompanymessage';
import Companymessage from './Companymessage';
import Companytocomercialmessage from './Companytocomercialmessage'
import Comercialmessage from './Comercialmessage';
import Comercialtorepresentantmessage from './Comercialtorepresentantmessage';
import Representantmessage from './Representantmessage';
import Updatemessage from './Updatemessage';
import Zones from './Zones';
import Zoneinfo from './Zoneinfo';
import TypeClientsTop from './TypeClientsTop';
import VideowallGroup from './VideowallGroup'
import VideowallCompany from './VideowallCompany';
import VideowallTops from './VideowallTops';
import MapDataVideowall from './MapDataVideowall';
import MapDataVideowallCompany from './MapDataVideowallCompany';
import Representantinfo from './Representantinfo';
import UsersApp from './UsersApp';
import UserApp from './UserApp';
import RegisterUser from './RegisterUser';
import GroupZonesComercial from './GroupZonesComercial';
import RepresentantRelations from './RepresentantRelations';
import UserType from './UserType';
import DeleteUser from './DeleteUser';
import EditUser from './EditUser';
import BackCompany from './BackCompany';
import BackEmployee from './BackEmployee';
import BackZone from './BackZone';
import BackSaleType from './BackSaleType';
import AddRelation from './AddRelation';
import EditRelation from './EditRelation';
import DeleteRelation from './DeleteRelation';
import DeleteRelations from './DeleteRelations';
import downloadpdf from './downloadpdf';
import Syncs from './Syncs';
import DoSync from './DoSync';
import Analitycs from './Analitycs';
import Devices from './Devices';
import AnalitycsUser from './AnalitycsUser';
import AnalitycsAllUser from './AnalitycsAllUser';
import downloadpdfAnalitics from './downloadpdfAnalitics';

export {
    Auth,
    Groupview,
    Groupcompanies,
    Groupdimensions,
    Company,
    Groupcompanydetails,
    Comercialsbytype,
    Representantbubbles,
    Companydetails,
    Changeme,
    KpiComercialGraphsUser,
    KpiRepresentantebyuser,
    KpiRepresentantebyzone,
    KpiRepresentantegraphsuser,
    KpiRepresentantegraphszone,
    ComercialUser,
    ComercialZone,
    Companybubbledetails,
    ComercialInfoUser,
    ComercialInfoZone,
    RepresentantsUser,
    RepresentantsZone,
    Groupinfo,
    Companyinfo,
    KpiComercialGraphsZone,
    KpiComercialUser,
    KpiComercialZone,
    KpiEmployeeUser,
    KpiEmployeeZone,
    KpiGraphsComercialClientUser,
    KpiGraphsComercialClientZone,
    KpiGraphsComercialClientUser2,
    KpiGraphsComercialClientZone2,
    ComercialbubbledetailUser,
    ComercialbubbledetailZone,
    ComercialVisitsZone,
    RepresentantbubblesZone,
    Representantbubblesdetailuser,
    ClientsEmployeeUser,
    ClientsEmployeeZone,
    KpiclientrepresentantZone,
    KpiclientrepresentantUser,
    KpiclientrepresentantUser2,
    KpiclientrepresentantZone2,
    RepresentantsviewsZone,
    RepresentantsviewsUser,
    Grouptops,
    Continenttops,
    Countrytops,
    Provincetops,
    Dimensionfiltertops,
    Comercialidtops,
    Comercialzonetops,
    Representantidtops,
    Representantzonetops,
    Comercialclientidtops,
    Comercialclientzonetops,
    Representantclientidtops,
    Representantclientzonetops,
    Grouptocompanymessage,
    Companymessage,
    Companytocomercialmessage,
    Comercialmessage,
    Comercialtorepresentantmessage,
    Representantmessage,
    Updatemessage,
    Zones,
    Zoneinfo,
    TypeClientsTop,
    VideowallGroup,
    VideowallCompany,
    VideowallTops,
    MapDataVideowall,
    MapDataVideowallCompany,
    Representantinfo,
    UsersApp,
    UserApp,
    RegisterUser,
    GroupZonesComercial,
    RepresentantRelations,
    UserType,
    DeleteUser,
    EditUser,
    BackCompany,
    BackEmployee,
    BackZone,
    BackSaleType,
    AddRelation,
    EditRelation,
    DeleteRelation,
    DeleteRelations,
    downloadpdf,
    Syncs,
    DoSync,
    Analitycs,
    Devices,
    AnalitycsUser,
    AnalitycsAllUser,
    downloadpdfAnalitics
};