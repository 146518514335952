import Model from "../Model";

class MapDataVideowall extends Model{

    buildUrl ({ param }) {
        return ['/videowall/region', param];
    }

    all(config = {}) {
        return this.request({ method: 'POST', action: 'all', ...config });
    }
}


export default new MapDataVideowall;