import Model from "../Model";

class Provincetops extends Model{

    buildUrl ({ param }) {
        return ['/province', param];
    }

    all(config = {}) {
        return this.request({ method: 'POST', action: 'all', ...config });
    }
}


export default new Provincetops;