import Model from "../Model";

class Updatemessage extends Model{

    buildUrl () {
        return ['internalmessage/updatechecked'];
    }

    all(config = {}) {
        return this.request({ method: 'POST', action: 'all', ...config });
    }

    update(form) {
        return this.request({
            action: 'store',
            method: 'POST',
            data: form
        });
    }
}


export default new Updatemessage;