import Model from "../Model";

class DoSync extends Model{

    buildUrl ({ param }) {
        return ['/synchro/make_synchro', param];
    }

    all(form, config = {}) {
        return this.request({ method: 'POST', action: 'all', data: form, ...config });
    }
}


export default new DoSync;