(function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
})(window, document, 'script', 'dataLayer', process.env.VUE_APP_TAG_MANAGER);

import Vue from 'vue';
import App from './App.vue';
import router from './router';

import './assets/css/main.css';
import '@mdi/font/css/materialdesignicons.min.css';

//IMPORTAMOS VUE HIGHCHART, PARA GRÁFICAS
import VueHighcharts from 'vue-highcharts';
//DECLARAMOS LOS SETTINGS GLOBALES DE HIGHCHART:
import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more'
import solidGauge from 'highcharts/modules/solid-gauge';
import wordcloud from 'highcharts/modules/wordcloud';
import loadMap from 'highcharts/modules/map';
import Treemap from 'highcharts/modules/treemap';



import Buefy from 'buefy'
import 'buefy/dist/buefy.css'

Vue.use(Buefy)

const   colors = ['white','green','red']

Highcharts.setOptions({
    colors: colors,
    lang: {
        months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        shortMonths: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
        weekdays: ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'],
        thousandsSep: '.'
    }
});
highchartsMore(Highcharts);
solidGauge(Highcharts);
Treemap(Highcharts);
wordcloud(Highcharts);
loadMap(Highcharts);
Vue.use(VueHighcharts);

//ELEMENT
import element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/es'
Vue.use(element, {locale});

//LODASH
import VueLodash from 'vue-lodash'
Vue.use(VueLodash)

//AVATAR
import VueLetterAvatar from 'vue-letter-avatar';
Vue.use(VueLetterAvatar);

import VueInitialsImg from 'vue-initials-img';
Vue.use(VueInitialsImg);

import i18n from '@/i18n/i18n'
Vue.use(i18n)

var language = '';

if (localStorage.getItem('locale') === 'es-ES') {
    language = 'es'
} else {
    if (localStorage.getItem('locale') === 'fr-FR')
        language = 'fr'
    else
    if (localStorage.getItem('locale') === 'it-IT')
        language = 'it'
    else
    if (localStorage.getItem('locale') === 'de-DE')
        language = 'de'
    else
        language = 'en-gb'
}

// VUEMOMENT
const moment = require('moment')
require('moment/locale/'+language)
Vue.use(require('vue-moment'), {moment});


// TOUCH
var VueTouch = require('vue-touch')
Vue.use(VueTouch, {name: 'v-touch'})

//GOOGLE MAPS
import * as VueGoogleMaps from 'vue2-google-maps';
Vue.use(VueGoogleMaps, {
    load:{
        key: 'AIzaSyCcPmT3tJq2r25QCA3YxB03pz6Z8riB8SM',
    },
    //libraries: 'places'
    installComponents: true,

})

//VUE AXIOS
import axios from 'axios'
import VueAxios from 'vue-axios'

const http = axios.create({
    // withCredentials: true
});


http.interceptors.request.use(
    (config) => {
        let token = state.token
        if (token) {
            config.headers['Authorization'] = `Bearer ${ token }`;
        }
        return config;
    },
    (error) => { return Promise.reject(error) }
);



Vue.use(VueAxios, http);
Vue.axios.defaults.baseURL = process.env.VUE_APP_BASEURL

Vue.config.productionTip = false;
import { state, actions } from '@/store';
Vue.mixin({
    data(){
        return{

        }
    },
    methods: {
        reduceBigNumbers: function(number, decimals) {
            return reduceBigNumbers(number, decimals);
        },
        numberFormat: function(number, decimals) {
            return numberFormat(number, decimals);
        }
    },
    computed:{
        periodo(){
            switch (state.period) {
                case 'month':
                    return this.$t("monthly")
                    break;
                case 'quarter':
                    return this.$t("quarterly")
                    break;
                case 'year':
                    return this.$t("annual")
                    break;
            }
        }
    }
});



Vue.filter('reduceBigNumbers', function(number, decimals) {
    return reduceBigNumbers(number, decimals)
});
Vue.filter('numberFormat', function(number, decimals) {
    return numberFormat(number, decimals)
});



export function reduceBigNumbers(number, decimals=2) {
    if (number > 1000000 || number < -1000000) {
        return numberFormat(number / 1000000, decimals) + 'M';
    }

    if (number > 1000 || number < -1000) {
        return numberFormat(number / 1000, decimals) + 'K';
    }

    if(number % 1 == 0){
        return numberFormat(number, 0);
    } else {
        return numberFormat(number, decimals);
    }
}

export function numberFormat(number, decimals) {

    if(typeof number == 'number'){

        decimals = (decimals != undefined) ?  decimals : 2
        return parseFloat(number).toLocaleString('en', {
            maximumFractionDigits: decimals,
            minimumFractionDigits: decimals
        }).replace(/,/g, '@').replace('.', ',').replace(/@/g, '.');
        // return parseFloat(number.toFixed(parseFloat(decimals))).toLocaleString('es-ES');
    }

}

import filterMonths from './components/filterMonths.vue'
Vue.component('filterMonths', filterMonths)

new Vue({
    router,
    i18n,
    render: h => h(App)
}).$mount('#app')
