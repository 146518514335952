import Model from "../Model";

class KpiRepresentantegraphszone extends Model{

    buildUrl ({ param }) {
        return ['kpiemployee/getdimensionbyzoneregion', param];
    }

    all(config = {}) {
        return this.request({ method: 'POST', action: 'all', ...config });
    }
}


export default new KpiRepresentantegraphszone;